import { LocalRequisition } from '@/modules/public-shop/types/requisitionLine'
import useOrganisationId from '@/modules/shared/hooks/params/useOrganisationId'
import { useLocalStorage } from '@/modules/shared/utils/useLocalStorage'

export const LOCAL_REQUISITION_KEY = 'local-requisition'

export function useCreateLocalnRequisition() {
  const organisationId = useOrganisationId()
  useLocalStorage<LocalRequisition>(`${LOCAL_REQUISITION_KEY}-${organisationId}`, {
    lines: [],
    totalTaxValue: 0,
    totalValue: 0,
  })
}
