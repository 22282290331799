import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import BalanceContainerRow from '@/modules/invoices/pages/invoice/page-header/balance-panel/BalanceContainerRow'
import CartSummaryTableView from '@/modules/public-shop/cart/summary/table-view/CartSummaryTableView'
import { useGetLocalRequisition } from '@/modules/public-shop/hooks/useGetLocalRequisition'
import SearchInput from '@/modules/shared/components/search-input/SearchInput'
import { useMoney } from '@/modules/shared/hooks/useMoney'
import { useWatchQueryParams } from '@/modules/shared/hooks/useWatchQueryParams'
import { CartIcon } from '@/modules/shared/icons/CartIcon'

export default function CartSummary() {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const { searchValue } = useWatchQueryParams()
  const { requisition } = useGetLocalRequisition()
  const [filteredLines, setFilteredLines] = useState(requisition.lines)

  useEffect(() => {
    setFilteredLines((prev) => {
      if (searchValue === '') return requisition.lines
      return prev.filter((line) => {
        const brandDescription = line.product.longDescription || ''
        return brandDescription.toLowerCase().includes(searchValue.toLowerCase())
      })
    })
  }, [searchValue, requisition.lines])

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <CartIcon className="size-9" />
          <p className="ml-3 text-sm">{t('general.cartSummary', 'Cart Summary')}</p>
        </div>
      </div>
      <SearchInput placeholder={t('general.search', 'Search')} />
      <CartSummaryTableView lines={filteredLines} />
      <div className="mt-12 flex flex-col gap-y-1">
        <div className="border bg-white px-4 py-3">
          <strong className="text-sm">{t('general.cartTotal', 'Cart Total')}</strong>
        </div>
        <div className="border bg-white">
          <div className="flex flex-col gap-1 p-2 text-sm">
            <BalanceContainerRow
              title={t('general.totalTaxExclusive', 'Total (Excluding Tax)')}
              value={requisition.totalValue - requisition.totalTaxValue}
              dataTestId="cart-summary-subtotal"
            />
            <BalanceContainerRow
              title={t('general.totalTax', 'Total Tax')}
              value={requisition.totalTaxValue}
              dataTestId="cart-summary-totaltax"
            />
          </div>
          <hr />
          <div className="flex flex-col gap-1 p-2 text-sm" data-testid="cart-summary-total">
            <div className="flex items-center justify-between px-2 text-lg font-bold" data-testid="invoice-total">
              <p>{t('general.cartTotal', 'Cart Total')}</p>
              {formatMoney({
                amount: requisition.totalValue,
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
