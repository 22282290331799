import CoverImage from '@/assets/images/Background.png'
import OverlayLogo from '@/assets/images/gilmores.png'

interface SupplierBannerProps {
  coverImage: string | undefined | null
  overlayLogo: string | undefined | null
}

export default function SupplierBanner({ coverImage, overlayLogo }: SupplierBannerProps) {
  return (
    <div
      className="flex h-48 w-full items-center justify-center rounded-md bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url('${coverImage || CoverImage}')`,
      }}
    >
      <img src={overlayLogo || OverlayLogo} alt="OverlayLogo" data-testid="overlayLogo" className="h-2/3" />
    </div>
  )
}
