import { Disclosure } from '@headlessui/react'
import { PropsWithChildren } from 'react'

import { ChevronDownIcon } from '@/modules/shared/icons/ChevronDownIcon'
import { classNames } from '@/modules/shared/utils/classNames'

interface AdvancedSearchFilterProps extends PropsWithChildren {
  buttonTestId: string
  buttonTitle: string
}

const AdvancedSearchFilter = ({ buttonTestId, buttonTitle, children }: AdvancedSearchFilterProps) => {
  return (
    <section className="px-4 pb-4 pt-3">
      <Disclosure defaultOpen>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full items-center justify-between py-2" data-testid={buttonTestId}>
              <span className="text-sm">{buttonTitle}</span>
              <ChevronDownIcon
                className={classNames('size-5 transition-all duration-300', {
                  '-rotate-180 transform': open,
                })}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="mt-2 space-y-3">{children}</Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </section>
  )
}

export default AdvancedSearchFilter
