import { CataloguedProduct } from '@/graphql/purchase-plus-public/generated/public_graphql'
import PublicProductCard from '@/modules/public-shop/components/PublicProductCard'
import { useGetLocalRequisition } from '@/modules/public-shop/hooks/useGetLocalRequisition'
import { useWindowSize } from '@/modules/shared/hooks/useWindowSize'
import { classNames } from '@/modules/shared/utils/classNames'

interface PublicProductTileViewProps {
  publicProducts: CataloguedProduct[]
}

export default function PublicProductTileView({ publicProducts }: PublicProductTileViewProps) {
  const { threeXL, fourXL, fiveXL } = useWindowSize()
  const { requisition } = useGetLocalRequisition()

  return (
    <div
      className={classNames('grid gap-2', {
        'grid-cols-4': threeXL,
        'grid-cols-5': fourXL,
        'grid-cols-6': fiveXL,
        'md:grid-cols-2 lg:grid-cols-3': !threeXL && !fourXL && !fiveXL,
      })}
      data-testid="products-tile-view"
    >
      {publicProducts.map((product) => (
        <PublicProductCard key={product.id} publicProduct={product} lines={requisition.lines} />
      ))}
    </div>
  )
}
