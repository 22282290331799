import { Link, generatePath } from 'react-router-dom'

import CoverImage from '@/assets/images/Background.png'
import OverlayLogo from '@/assets/images/gilmores.png'
import Logo from '@/assets/images/logo.png'

interface SupplierCardProps {
  squareLogo: string | undefined | null
  overlayLogo: string | undefined | null
  coverImage: string | undefined | null
  supplierName: string
  supplierTagLine: string
  organisationId: string
}

export default function SupplierCard({
  squareLogo,
  overlayLogo,
  coverImage,
  supplierName,
  supplierTagLine,
  organisationId,
}: SupplierCardProps) {
  return (
    <Link
      className="h-64 rounded-md border shadow-sm transition-all hover:opacity-90"
      to={generatePath('/shop/:organisationId', { organisationId })}
      data-testid="supplier-card"
    >
      <div
        data-testid="coverImage"
        className="flex h-2/3 items-center justify-center rounded-t-md bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url('${coverImage || CoverImage}')`,
        }}
      >
        <img src={overlayLogo || OverlayLogo} alt="OverlayLogo" data-testid="overlayLogo" className="h-2/3" />
      </div>
      <div className="flex h-1/3 items-center rounded-b-md bg-white p-4">
        <div className="flex gap-x-3">
          <img src={squareLogo || Logo} className="size-14" alt="SquareLogo" data-testid="squareLogo" />
          <span className="flex flex-col justify-center gap-y-1">
            <p className="text-sm font-bold">{supplierName}</p>
            <p className="text-xs text-gray-500">{supplierTagLine}</p>
          </span>
        </div>
      </div>
    </Link>
  )
}
