import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import Button from '@/modules/shared/components/button/Button'

interface EmptyStateProps {
  img?: string
  message: string
  title?: string
  hideClear?: boolean
}

export default function EmptyState({ img, message, title, hideClear }: EmptyStateProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <div className="mb-3 flex flex-col items-center justify-center" data-testid="empty-state">
      {!img ? (
        <svg
          data-testid="default-empty-state-svg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 55.94 55.94"
          className="h-64 md:h-72"
        >
          <path
            fill="#e5e7eb"
            d="m30.6,23.86c-2.51-2.51-6.6-2.51-9.12,0-2.51,2.51-2.51,6.6,0,9.12s6.21,2.51,8.74.35c0,0,0,0,0,.01l3.88,3.88c.2.2.52.2.72,0s.2-.52,0-.72l-3.88-3.88s0,0-.01,0c2.16-2.53,2.05-6.35-.35-8.74Z"
          />
          <path
            fill="#d1d4d7"
            d="m13.26,28.21h-5.45c-.23,0-.42-.19-.42-.42s.19-.42.42-.42h5.45c.23,0,.42.19.42.42s-.19.42-.42.42Z"
          />
          <path
            fill="#d1d4d7"
            d="m39.96,45.7h-4.28c-.23,0-.42-.19-.42-.42s.19-.42.42-.42h4.28c.23,0,.42.19.42.42s-.19.42-.42.42Z"
          />
          <path
            fill="#d1d4d7"
            d="m19.39,12.16h-4.28c-.23,0-.42-.19-.42-.42s.19-.42.42-.42h4.28c.23,0,.42.19.42.42s-.19.42-.42.42Z"
          />
          <path
            fill="#d1d4d7"
            d="m17.02,47.78h-2.54c-.23,0-.42-.19-.42-.42s.19-.42.42-.42h2.54c.23,0,.42.19.42.42s-.19.42-.42.42Z"
          />
          <path
            fill="#d1d4d7"
            d="m38.01,14.06h-2.54c-.23,0-.42-.19-.42-.42s.19-.42.42-.42h2.54c.23,0,.42.19.42.42s-.19.42-.42.42Z"
          />
          <path
            fill="#d1d4d7"
            d="m44.76,36.71h-2.54c-.23,0-.42-.19-.42-.42s.19-.42.42-.42h2.54c.23,0,.42.19.42.42s-.19.42-.42.42Z"
          />
          <polygon
            fill="#9ca3af"
            points="13.12 34.05 14.35 33.34 15.14 32 15.87 33.37 17.12 34.06 15.82 34.76 15.12 36.03 14.38 34.74 13.12 34.05"
          />
          <polygon
            fill="#9ca3af"
            points="40.73 25.24 41.97 24.53 42.75 23.2 43.48 24.56 44.72 25.25 43.42 25.94 42.73 27.21 42 25.92 40.73 25.24"
          />
          <polygon
            fill="#e5e7eb"
            points="38.27 32.05 39.47 31.37 40.23 30.07 40.93 31.39 42.14 32.06 40.88 32.73 40.21 33.96 39.5 32.71 38.27 32.05"
          />
          <polygon
            fill="#d1d4d7"
            points="34.78 18.79 35.67 18.28 36.24 17.32 36.76 18.3 37.66 18.8 36.73 19.31 36.22 20.22 35.69 19.29 34.78 18.79"
          />
          <polygon
            fill="#d1d4d7"
            points="13.39 17.94 15.24 16.83 16.41 14.73 17.5 16.87 19.37 17.95 17.42 19.05 16.38 21.04 15.28 19.02 13.39 17.94"
          />
          <polygon
            fill="#9ca3af"
            points="25.99 11.47 27.99 10.33 29.26 8.17 30.44 10.37 32.45 11.49 30.35 12.61 29.22 14.67 28.04 12.58 25.99 11.47"
          />
          <path
            fill="#9ca3af"
            d="m31.47,22.22c-2.51-2.51-6.6-2.51-9.12,0-2.51,2.51-2.51,6.6,0,9.12s6.21,2.51,8.74.35c0,0,0,0,0,.01l4.46,4.46c.2.2.52.2.72,0s.2-.52,0-.72l-4.46-4.46s0,0-.01,0c2.16-2.53,2.05-6.35-.35-8.74Zm-.58,8.53c-2.19,2.19-5.76,2.19-7.95,0s-2.19-5.76,0-7.95,5.76-2.19,7.95,0,2.19,5.76,0,7.95Z"
          />
        </svg>
      ) : (
        <img role="img" src={img} className="h-64 md:h-72" alt="" />
      )}
      <div className="text-center">
        <span className="text-xs font-bold md:text-sm">{title}</span>
        <p className="text-xs text-gray-500 md:text-sm">{message}</p>
        {location.search !== '' && !hideClear && (
          <Button
            className="mt-4 rounded-md bg-gray-200 px-4 py-2 text-sm"
            onClick={() => navigate(window.location.pathname)}
          >
            {t('general.button.clearSearch', 'Clear Search')}
          </Button>
        )}
      </div>
    </div>
  )
}
