import { Button } from '@headlessui/react'
import { useState } from 'react'

import AddOrderInstructionModal from '@/modules/public-shop/cart/summary/table-view/AddOrderInstructionModal'
import { useUpdateLocalRequisitionRequisition } from '@/modules/public-shop/hooks/useUpdateLocalRequisition'
import { LocalRequisitionLine } from '@/modules/public-shop/types/requisitionLine'
import { NoteIcon } from '@/modules/shared/icons/NoteIcon'
import { TrashIcon } from '@/modules/shared/icons/TrashIcon'

interface CartSummaryOptionsProps {
  line: LocalRequisitionLine
}

export default function CartSummaryOptions({ line }: CartSummaryOptionsProps) {
  const { onDeleteRequisitionLine } = useUpdateLocalRequisitionRequisition()
  const [openOrderInstructionModal, setOpenOrderInstructionModal] = useState(false)
  return (
    <>
      <div className="flex items-center justify-center gap-x-1.5">
        <Button
          data-testid="add-order-instruction-btn"
          className="rounded-full bg-gray-200 p-1 text-gray-500"
          onClick={() => setOpenOrderInstructionModal(true)}
        >
          <NoteIcon className="size-7" />
        </Button>
        <Button
          data-testid="remove-product-btn"
          className="rounded-full bg-gray-200 p-1 text-gray-500"
          onClick={() => onDeleteRequisitionLine(Number(line.id))}
        >
          <TrashIcon className="size-7" />
        </Button>
      </div>
      <AddOrderInstructionModal
        setShowModal={setOpenOrderInstructionModal}
        showModal={openOrderInstructionModal}
        line={line}
      />
    </>
  )
}
