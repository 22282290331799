import { useMoney } from '@/modules/shared/hooks/useMoney'
import { classNames } from '@/modules/shared/utils/classNames'

interface BalanceContainerRowProps {
  title: string
  value: string | number | null | undefined
  canHover?: boolean
  dataTestId?: string
  currency?: string
}

export default function BalanceContainerRow({
  title,
  value,
  canHover,
  dataTestId,
  currency,
}: BalanceContainerRowProps) {
  const { formatMoney } = useMoney()

  return (
    <div
      data-testid={dataTestId}
      className={classNames('flex justify-between rounded-md px-2 py-1', { 'hover:bg-gray-100': canHover })}
    >
      <p className="text-gray-500">{title}</p>
      <p>{formatMoney({ amount: value, customCurrency: currency })}</p>
    </div>
  )
}
