import { useTranslation } from 'react-i18next'

import { CataloguedProduct } from '@/graphql/purchase-plus-public/generated/public_graphql'
import { useUpdateLocalRequisitionRequisition } from '@/modules/public-shop/hooks/useUpdateLocalRequisition'
import { LocalRequisitionLine } from '@/modules/public-shop/types/requisitionLine'
import Button from '@/modules/shared/components/button/Button'
import { Stepper } from '@/modules/shared/components/stepper/Stepper'

interface AddItemProps {
  publicProduct: CataloguedProduct
  lines: LocalRequisitionLine[]
}

export default function AddItem({ publicProduct, lines }: AddItemProps) {
  const { t } = useTranslation()
  const { onCreateRequisitionLine, onUpdateRequisitionLine, onDeleteRequisitionLine } =
    useUpdateLocalRequisitionRequisition()
  const lineFoundInCart = lines.find((line) => line.id === publicProduct.id)

  const onAddItem = () => {
    if (lineFoundInCart) return
    onCreateRequisitionLine({
      lineId: publicProduct.id,
      productId: publicProduct?.id || 0,
      unitPrice: publicProduct.sellUnitPrice || 0,
      quantity: 1,
      taxPercentage: publicProduct.sellUnitTaxPercentage || 0,
      product: {
        image: publicProduct?.image || '',
        longDescription: publicProduct?.longDescription || '',
      },
    })
  }

  const onQuantityChange = (lineId: number, newQuantity: number) => {
    if (newQuantity === 0) {
      onDeleteRequisitionLine(lineId)
    } else {
      onUpdateRequisitionLine({ lineId, quantity: newQuantity })
    }
  }

  return (
    <>
      {!lineFoundInCart ? (
        <Button
          data-testid="add-product-btn"
          className="w-40 rounded-full bg-gray-200 py-2.5 text-sm"
          onClick={onAddItem}
        >
          {t('general.addItem', 'Add Item')}
        </Button>
      ) : (
        <div className="w-40">
          <Stepper
            buttonClassName="bg-primary"
            value={lineFoundInCart.quantity || 0}
            stepperAriaLabel={t('shopPage.stepperAriaLabel', 'Entering number of products')}
            onChange={(newQty: number) => {
              onQuantityChange(lineFoundInCart.id, newQty)
            }}
          />
        </div>
      )}
    </>
  )
}
