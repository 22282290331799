import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PublicPageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './public_graphql'

export const GetPublicShopProductsDocument = gql`
  query GetPublicShopProducts(
    $publicShopId: Int!
    $after: String
    $before: String
    $filters: PublicCataloguedProductsQueryFiltersInput
    $first: Int
    $last: Int
    $sorts: [PublicCataloguedProductsQuerySortsInput!]
  ) {
    publicShop(id: $publicShopId) {
      id
      publicCataloguedProducts(
        after: $after
        before: $before
        filters: $filters
        first: $first
        last: $last
        sorts: $sorts
      ) {
        edges {
          node {
            id
            image
            longDescription
            sellUnitPrice
            sellUnitTax
            sellUnitTaxPercentage
            supplierProductCode
          }
        }
        pageInfo {
          ...PublicPageInfo
        }
      }
    }
  }
  ${PublicPageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetPublicShopProductsQuery, Types.GetPublicShopProductsQueryVariables>
