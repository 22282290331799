import { useEffect, useState } from 'react'

import Button from '@/modules/shared/components/button/Button'
import NumberInput from '@/modules/shared/components/number-input/NumberInput'
import { useDebounce } from '@/modules/shared/hooks/useDebounce'
import MinusIcon from '@/modules/shared/icons/MinusIcon'
import { PlusIcon } from '@/modules/shared/icons/PlusIcon'
import { classNames } from '@/modules/shared/utils/classNames'

interface StepperProps {
  value: number
  onChange: (value: number) => void
  stepperAriaLabel: string
  loading?: boolean
  disabled?: boolean
  beginChange?: () => void
  buttonClassName?: string
}

export function Stepper({
  value,
  onChange,
  stepperAriaLabel,
  loading = false,
  disabled = false,
  beginChange,
  buttonClassName,
}: StepperProps) {
  const [numValue, setNumValue] = useState<number>(value)
  const [isInput, setIsInput] = useState(false)
  const valueChangedFromButton = useDebounce(numValue, 500)

  useEffect(() => {
    // only activate debounce when we click the stepper buttons
    if (!isInput && numValue !== value) {
      // call this when the change is initiated.
      onChange(valueChangedFromButton)
    }
  }, [valueChangedFromButton])

  useEffect(() => {
    //  don't need to activate debounce when typing into the input field since we can rely on the useNumberField default behavior, which triggers onChange only when the input is blurred.
    if (isInput && numValue !== value) {
      onChange(numValue)
    }
  }, [numValue])

  useEffect(() => {
    if (numValue !== value) setNumValue(value)
  }, [value])

  return (
    <div className="flex items-center justify-center gap-x-1.5">
      <Button
        data-testid="stepper-action-decrease"
        loading={loading}
        disabled={disabled}
        className={classNames(
          'flex size-10 items-center justify-center rounded-full bg-primary text-white',
          buttonClassName
        )}
        onClick={() => {
          setIsInput(false)
          if (beginChange) beginChange()
          if (!Number.isInteger(numValue)) setNumValue(Math.floor(numValue))
          else if (numValue >= 1) setNumValue(numValue - 1)
        }}
      >
        <MinusIcon className="size-6" />
      </Button>
      <NumberInput
        data-testid="stepper-action-input"
        value={numValue}
        showStepper
        aria-label={stepperAriaLabel}
        isDisabled={disabled || loading}
        loading={loading}
        className="h-10 w-16 border-gray-300 p-1 text-center focus:border-primary focus:ring-primary"
        onChange={(e) => {
          setIsInput(true)
          if (beginChange) beginChange()
          if (isNaN(e)) {
            setNumValue(0)
          } else {
            setNumValue(e)
          }
        }}
        minValue={0}
        maxValue={99999}
        formatOptions={{
          maximumFractionDigits: 3,
        }}
      />
      <Button
        data-testid="stepper-action-increase"
        disabled={disabled}
        loading={loading}
        className={classNames(
          'flex size-10 items-center justify-center rounded-full bg-primary text-white',
          buttonClassName
        )}
        onClick={() => {
          setIsInput(false)
          if (beginChange) beginChange()
          if (!Number.isInteger(numValue)) setNumValue(Math.ceil(numValue))
          else if (numValue < 999) setNumValue(numValue + 1)
        }}
      >
        <PlusIcon className="size-6" />
      </Button>
    </div>
  )
}
