import { useQuery } from '@apollo/client'

import { GetPublicShopProductsDocument } from '@/graphql/purchase-plus-public/generated/getPublicShopProducts.generated'
import { CataloguedProduct, SortDirectionEnum } from '@/graphql/purchase-plus-public/generated/public_graphql'
import { PublicCataloguedProductsQuerySortsPropertyEnum } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import { PURCHASE_PLUS_PUBLIC_GRAPHQL_API } from '@/modules/shared/constants'
import useShopId from '@/modules/shared/hooks/params/useShopId'
import { useWatchQueryParams } from '@/modules/shared/hooks/useWatchQueryParams'
import { checkNetworkStatus } from '@/modules/shared/utils/checkNetworkStatus'
import { extractEdges } from '@/modules/shared/utils/extractEdges'
import { onFetchMore, onNextPage, onPreviousPage } from '@/modules/shared/utils/paginationUtils'

export function useGetPublicShopProducts() {
  const paginationResultsPerPage = 20
  const shopId = useShopId()
  const { searchValue } = useWatchQueryParams()

  const { data, networkStatus, error, refetch, fetchMore } = useQuery(GetPublicShopProductsDocument, {
    variables: {
      first: paginationResultsPerPage,
      after: null,
      publicShopId: Number(shopId),
      filters: {
        searchText: searchValue,
      },
      sorts: [
        {
          property: PublicCataloguedProductsQuerySortsPropertyEnum.CreatedAt,
          direction: SortDirectionEnum.Desc,
        },
      ],
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    context: { uri: PURCHASE_PLUS_PUBLIC_GRAPHQL_API },
  })

  const pageInfo = data?.publicShop?.publicCataloguedProducts?.pageInfo

  return {
    publicProducts: extractEdges<CataloguedProduct>(data?.publicShop?.publicCataloguedProducts),
    loadingStates: checkNetworkStatus(networkStatus),
    pageInfo,
    error,
    refetch,
    paginationResultsPerPage,
    onNextPage: () =>
      onNextPage({
        endCursor: pageInfo?.endCursor || null,
        refetch,
        paginationResultsPerPage,
      }),
    onPreviousPage: () =>
      onPreviousPage({
        startCursor: pageInfo?.startCursor || null,
        refetch,
        paginationResultsPerPage,
      }),
    onFetchMore: () =>
      onFetchMore({
        endCursor: pageInfo?.endCursor || null,
        fetchMore,
        paginationResultsPerPage,
      }),
  }
}
