import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PurchasePlusPageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetOrganisationFlatAccountsDocument = gql`
  query GetOrganisationFlatAccounts(
    $organisationId: Int!
    $filters: AccountsQueryFiltersInput
    $after: String
    $before: String
    $first: Int
    $last: Int
    $sorts: [AccountsQuerySortsInput!]
  ) {
    organisation(id: $organisationId) {
      id
      ... on Purchaser {
        allAccountsDisabled
        accountsPayableSystem {
          id
        }
        flatAccountCodes(filters: $filters, after: $after, before: $before, first: $first, last: $last, sorts: $sorts) {
          edges {
            node {
              id
              name
              code
              description
              enabled
              accountName: codedName
              parent {
                id
                name
                code
              }
              children {
                totalCount
              }
            }
          }
          pageInfo {
            ...PurchasePlusPageInfo
          }
        }
      }
    }
  }
  ${PurchasePlusPageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetOrganisationFlatAccountsQuery, Types.GetOrganisationFlatAccountsQueryVariables>
