import { useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'

import { GetAccountsPayableSystemFlatAccountsDocument } from '@/graphql/purchase-plus/generated/getAccountsPayableSystemFlatAccounts.generated'
import { Account } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import { PURCHASE_PLUS_GRAPHQL_API } from '@/modules/shared/constants'
import useApSystemId from '@/modules/shared/hooks/params/useApSystemId'
import { useWatchQueryParams } from '@/modules/shared/hooks/useWatchQueryParams'
import { checkNetworkStatus } from '@/modules/shared/utils/checkNetworkStatus'
import { extractEdges } from '@/modules/shared/utils/extractEdges'
import { onFetchMore } from '@/modules/shared/utils/paginationUtils'

interface UseGetAccountsPayableSystemFlatAccountsProps {
  accountsPayableSystemId?: number
  lazyQuery?: boolean
  isParent?: boolean
}

export default function useGetAccountsPayableSystemFlatAccounts(args?: UseGetAccountsPayableSystemFlatAccountsProps) {
  const { accountsPayableSystemId: accountsPayableSystemIdProp, lazyQuery, isParent } = args || {}
  const paginationResultsPerPage = 20
  const accountsPayableSystemId = useApSystemId()
  const { searchValue, accountType } = useWatchQueryParams()
  const [fetchAccountsPayableSystemFlatAccounts, { data, networkStatus, error, fetchMore, refetch }] = useLazyQuery(
    GetAccountsPayableSystemFlatAccountsDocument,
    {
      variables: {
        accountsPayableSystemId: Number(accountsPayableSystemIdProp || accountsPayableSystemId),
        filters: {
          searchText: searchValue,
          hasParent: accountType === null ? undefined : accountType,
          isParent,
        },
      },
      context: { uri: PURCHASE_PLUS_GRAPHQL_API },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    }
  )

  useEffect(() => {
    if (!lazyQuery) {
      fetchAccountsPayableSystemFlatAccounts()
    }
  }, [])

  const pageInfo = data?.accountsPayableSystem?.flatAccountCodes?.pageInfo

  return {
    fetchAccountsPayableSystemFlatAccounts,
    flatAccounts: extractEdges<Account>(data?.accountsPayableSystem?.flatAccountCodes),
    loadingStates: checkNetworkStatus(networkStatus),
    error,
    onFetchMoreFlatAccounts: () =>
      onFetchMore({
        endCursor: pageInfo?.endCursor || null,
        fetchMore,
        paginationResultsPerPage,
      }),
    pageInfo,
    networkStatus,
    refetch,
  }
}
