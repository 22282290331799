import { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import AppLayout from '@/modules/shared/layouts/app-layout/AppLayout'
import NotFound from '@/modules/shared/pages/not-found/NotFound'

// Inside app layout
const MySupplierRoutes = lazy(() => import('@/routes/shared-routes/MySupplierRoutes'))
const InvoiceRoutes = lazy(() => import('@/routes/shared-routes/InvoiceRoutes'))
const PurchaserProductRoutes = lazy(() => import('@/routes/shared-routes/PurchaserProductRoutes'))
const ReportRoutes = lazy(() => import('@/routes/shared-routes/ReportRoutes'))
const UserRoutes = lazy(() => import('@/routes/shared-routes/UserRoutes'))
const PaperlessSystemSettingsRoutes = lazy(() => import('@/routes/shared-routes/PaperlessSystemSettingsRoutes'))
const BarcodeRoutes = lazy(() => import('@/routes/shared-routes/BarcodeRoutes'))
const MyAccountRoutes = lazy(() => import('@/routes/shared-routes/MyAccountRoutes'))
const CreditNoteRoutes = lazy(() => import('@/routes/shared-routes/CreditNoteRoutes'))
const SupplierDirectoryRoutes = lazy(() => import('@/routes/shared-routes/SupplierRoutes'))
const UtilityRoutes = lazy(() => import('@/routes/shared-routes/UtilityRoutes'))
const ShopRoutes = lazy(() => import('@/routes/public-routes/ShopRoutes'))
const PaperlessDashboard = lazy(() => import('@/modules/purchasing/pages/dashboard/PaperlessDashboard'))

export default function PaperlessRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard" />} />

      <Route element={<AppLayout />}>
        <Route path="/dashboard" element={<PaperlessDashboard />} />
        <Route path="/invoices/*" element={<InvoiceRoutes />} />
        <Route path="/credit-notes/*" element={<CreditNoteRoutes />} />
        <Route path="/my-suppliers/*" element={<MySupplierRoutes />} />
        <Route path="/supplier-directory/*" element={<SupplierDirectoryRoutes />} />
        <Route path="/products/*" element={<PurchaserProductRoutes />} />
        <Route path="/my-suppliers" element={<MySupplierRoutes />} />
        <Route path="/reports/*" element={<ReportRoutes />} />
        <Route path="/users/*" element={<UserRoutes />} />
        <Route path="/settings/*" element={<PaperlessSystemSettingsRoutes />} />
        <Route path="/barcodes/*" element={<BarcodeRoutes />} />
        <Route path="/my-account/*" element={<MyAccountRoutes />} />
        <Route path="/utilities/*" element={<UtilityRoutes />} />
        <Route path="/shop/*" element={<ShopRoutes />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
