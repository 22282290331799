import {
  Maybe,
  PageInfo,
  Product,
  RequisitionLine,
  Scalars,
  User,
} from '@/graphql/purchasing/generated/purchasing_graphql'

export enum Breakpoints {
  SM = 640,
  MD = 768,
  LG = 1024,
  XL = 1280,
  XXL = 1536,
}

export enum Themes {
  Primary = 'primary',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Purple = 'purple',
}

export enum Dates {
  Long = 'Do MMMM YYYY h:mma',
  Medium = 'dddd, MMMM Do YYYY',
  Short = 'DD MMM YYYY',
  ShortOrdinal = 'Do MMM YYYY',
  ShortReverse = 'MMM Do YYYY',
  ShortYear = 'YYYY-MM-DD',
  Time = 'h:mma',
  MonthYear = 'MMMM YYYY',
}

export type FilterTabItem = {
  key: string
  filterValue: string
  target?: string
}

export enum CSSVariables {
  PrimaryColor = '--primary-color',
  PrimaryColorLight = '--primary-light',
  PrimaryColorLighter = '--primary-lighter',
}

export type SlideData = {
  id: string
  imageSrc: string
}

export type StatusMapping<T extends string> = Record<
  T,
  {
    content: string
    tooltip: string
    customStyles: string
  }
>
export enum DocumentType {
  Requisition = 'Trade::Requisition',
  PurchaseOrder = 'Trade::PurchaseOrder',
  Invoice = 'Trade::Invoice',
  CreditNote = 'Trade::CreditNote',
}

export enum Document {
  Requisition = 'requisition',
  PurchaseOrder = 'purchaseOrder',
  Invoice = 'invoice',
  Stocktake = 'stocktake',
  CreditNote = 'creditNote',
}

// used to replicate the tailwindCSS breakpoints
export type BreakpointKey = 'sm' | 'md' | 'lg' | 'xl' | 'twoXL' | 'threeXL' | 'fourXL' | 'fiveXL'
export type BreakpointsAsIntegers = Record<BreakpointKey, number>

export enum ReceivingNotesReceivedState {
  received = 'received',
  notReceived = 'notReceived',
}

export enum StocktakeState {
  Closed = 'closed',
  InProgress = 'in-progress',
}

export enum Env {
  Production = 'prd',
  Staging = 'stg',
  Development = 'dev',
  UAT = 'uat',
}

export type MaybeProps<T> = { [P in keyof T]: Maybe<T[P]> }

export type ProductType = RequisitionLine & {
  availableProduct?: Product
}

export interface PaginatedTable {
  paginationLoading: boolean
  pageInfo: Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'endCursor'>
  onNextPage: () => void
  onPreviousPage: () => void
}

export enum AttachmentType {
  Requisition,
  Invoice,
  CreditNote,
}

export interface Attachment {
  id: Scalars['Int']['output']
  fileFileName: Maybe<Scalars['String']['output']>
  caption: Maybe<Scalars['String']['output']>
  createdAt: Maybe<Scalars['ISO8601DateTime']['output']>
  updator: Maybe<User>
}

export type ButtonTheme = 'light' | 'dark'
