import { Controller } from 'react-hook-form'
import { useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { Product } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { ComboboxClient } from '@/modules/shared/components/combobox/client/ComboboxClient'
import NumberInput from '@/modules/shared/components/number-input/NumberInput'
import { packNameValues } from '@/modules/shared/components/pack-name-dropdown/PackNameValues'
import { Tooltip } from '@/modules/shared/components/tooltip/Tooltip'
import { HELP_LINK_PRODUCT_CREATION, HELP_LINK_PRODUCT_CREATION_AS_SUPPLIER } from '@/modules/shared/help'
import { QuestionMarkIcon } from '@/modules/shared/icons/QuestionMarkIcon'

interface ProductSellPackProps {
  product?: Product
  disabled?: boolean
}

export default function ProductSellPack({ product, disabled }: ProductSellPackProps) {
  const { t } = useTranslation()
  const { currentOrganisation } = useSessionUser()
  const formMethods = useFormContext()

  return (
    <div>
      <p className="font-bold">{t('createdProduct.sellPack.title', 'Sell Pack and Sell Quantity')}</p>
      <p className="text-gray-500">
        <Trans t={t} i18nKey="updateProductModal.sellPack.description">
          The sell pack and sell pack quantity describes what will be received when an order for 1 of these items is
          placed. For example "Carton of 24", for a Carton of 24 x 375ml Cans. Please read our detailed{' '}
          <a
            data-testid="view-support-link"
            href={
              currentOrganisation?.isAPurchaser ? HELP_LINK_PRODUCT_CREATION : HELP_LINK_PRODUCT_CREATION_AS_SUPPLIER
            }
            target="_blank"
            rel="noreferrer"
            className="text-primary"
          >
            product creation guide
          </a>{' '}
          to see examples of common sell pack configurations.
        </Trans>
      </p>
      {product?.stockItem && (
        <div className="mt-3 rounded-md bg-gray-200 p-4">
          {t('product.isDisabledSellPack', 'This product is held as a Stock Item and these fields cannot be changed.')}
        </div>
      )}
      <div className="mt-3 flex w-1/2 flex-col gap-y-3">
        <div className="flex flex-col gap-y-1">
          <p className="flex items-center font-bold">
            {t('general.sellPackName', 'Sell Pack Name')}
            <Tooltip
              content={t(
                'utilityProducts.sellPackNameTooltip',
                'The name of the Sell Pack e.g. "Carton" for a Carton of 24 x 375ml Cans.'
              )}
            >
              <QuestionMarkIcon className="ml-1 size-4" />
            </Tooltip>
          </p>
          <Controller
            control={formMethods.control}
            name="itemSellPackName"
            render={({ field }) => (
              <ComboboxClient
                items={packNameValues}
                keyExtractor={(e) => e?.key || ''}
                defaultValue={
                  product?.itemSellPackName
                    ? { key: product?.itemSellPackName, value: product?.itemSellPackName }
                    : { key: 'null', value: '--' }
                }
                keyFilter="value"
                onSelected={(e) => field.onChange(e.key === 'null' ? null : e.key)}
                testId="sellPackName"
                disabled={disabled || product?.stockItem}
                hasError={!!formMethods.formState.errors.itemSellPackName}
                errorMessage={t('product.sellPackNameError', 'Sell pack name is required.')}
              />
            )}
          />
        </div>
        <div className="flex flex-col gap-y-1">
          <p className="flex items-center font-bold">
            {t('general.sellPackQuantity', 'Sell Pack Quantity')}
            <Tooltip
              content={t(
                'utilityProducts.sellQuantityTooltip',
                'The number of units contained in the Sell Pack e.g. "24" for a Carton of 24 x 375ml Cans.'
              )}
            >
              <QuestionMarkIcon className="ml-1 size-4" />
            </Tooltip>
          </p>
          <Controller
            control={formMethods.control}
            name="itemSellQuantity"
            render={({ field }) => (
              <NumberInput
                className="h-[2.875rem] w-full rounded-md text-sm"
                defaultValue={product?.itemSellQuantity || undefined}
                onChange={(e) => field.onChange(e || null)}
                aria-label="item-sell-quantity"
                data-testid="sell-pack-quantity-input"
                placeholder={t('general.sellPackQuantity', 'Sell Pack Quantity')}
                isDisabled={disabled || product?.stockItem}
                hasError={!!formMethods.formState.errors.itemSellQuantity}
                errorMessage={t('product.sellPackQuantityError', 'Sell pack quantity is required.')}
              />
            )}
          />
        </div>
      </div>
    </div>
  )
}
