import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@/modules/shared/components/button/Button'
import { MultiRowSelection } from '@/modules/shared/components/table/types'

interface TableMultiRowControlProps<T> {
  multiRowSelection?: MultiRowSelection<T>
  selectedRows: T[]
  setSelectedRows: Dispatch<SetStateAction<T[]>>
}

export default function TableMultiRowControl<T>({
  multiRowSelection,
  selectedRows,
  setSelectedRows,
}: TableMultiRowControlProps<T>) {
  const { t } = useTranslation()

  // Deselect all rows
  const onClearSelection = () => {
    setSelectedRows([])
  }

  if (!multiRowSelection) return null

  const actions = multiRowSelection.actions.filter((action) => (action.show === undefined ? true : action.show))

  return (
    <div className="flex items-center justify-between border border-b-0 bg-white px-4 py-3 text-sm">
      <div className="flex items-center gap-x-2">
        {actions.map((action, index) => {
          const hyphenatedActionText = action.text.toLocaleLowerCase().replace(/\s+/g, '-')
          return (
            <Button
              key={index}
              className="flex items-center gap-x-2 rounded-md bg-gray-200 px-4 py-2 text-sm"
              data-testid={`multi-row-${hyphenatedActionText}-action`}
              onClick={() => action.onClick(selectedRows)}
            >
              <span className="text-sm">{action.text}</span>
            </Button>
          )
        })}
      </div>
      <div className="flex items-center gap-x-3">
        <span>{t('general.rowsSelected', '{{number}} Selected', { number: selectedRows.length })}</span>
        <Button
          onClick={() => onClearSelection()}
          className="flex items-center gap-x-2 rounded-md bg-gray-200 px-4 py-2 text-sm"
        >
          {t('general.clearSelection', 'Clear Selection')}
        </Button>
      </div>
    </div>
  )
}
