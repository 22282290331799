import SearchByProductCode from '@/modules/public-shop/components/advanced-search/SearchByProductCode'
import CategoryFilter from '@/modules/public-shop/components/advanced-search/category-filter/CategoryFilter'

export default function AdvancedSearch() {
  return (
    <div className="h-full divide-y rounded-md bg-white shadow">
      <CategoryFilter />
      <SearchByProductCode />
    </div>
  )
}
