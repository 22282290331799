import { useQuery } from '@apollo/client'

import { GetPublicShopsDocument } from '@/graphql/purchase-plus-public/generated/getPublicShops.generated'
import { PublicShopsQuerySortsPropertyEnum } from '@/graphql/purchase-plus-public/generated/public_graphql'
import { SortDirectionEnum, Supplier } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import { PURCHASE_PLUS_PUBLIC_GRAPHQL_API } from '@/modules/shared/constants'
import { useWatchQueryParams } from '@/modules/shared/hooks/useWatchQueryParams'
import { checkNetworkStatus } from '@/modules/shared/utils/checkNetworkStatus'
import { extractEdges } from '@/modules/shared/utils/extractEdges'
import { onFetchMore, onNextPage, onPreviousPage } from '@/modules/shared/utils/paginationUtils'

export default function useGetPublicShops() {
  const paginationResultsPerPage = 20
  const { searchValue } = useWatchQueryParams()

  const { data, networkStatus, error, refetch, fetchMore } = useQuery(GetPublicShopsDocument, {
    variables: {
      first: paginationResultsPerPage,
      after: null,
      filters: {
        name: searchValue,
      },
      sorts: [
        {
          property: PublicShopsQuerySortsPropertyEnum.CreatedAt,
          direction: SortDirectionEnum.Desc,
        },
      ],
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    context: { uri: PURCHASE_PLUS_PUBLIC_GRAPHQL_API },
  })

  const pageInfo = data?.publicShops?.pageInfo

  return {
    publicShops: extractEdges<Supplier>(data?.publicShops),
    loadingStates: checkNetworkStatus(networkStatus),
    error,
    refetch,
    pageInfo,
    networkStatus,
    paginationResultsPerPage,
    onNextPage: () =>
      onNextPage({
        endCursor: pageInfo?.endCursor || null,
        refetch,
        paginationResultsPerPage,
      }),
    onPreviousPage: () =>
      onPreviousPage({
        startCursor: pageInfo?.startCursor || null,
        refetch,
        paginationResultsPerPage,
      }),
    onFetchMore: () =>
      onFetchMore({
        endCursor: pageInfo?.endCursor || null,
        fetchMore,
        paginationResultsPerPage,
      }),
  }
}
