import { Route, Routes } from 'react-router-dom'

import SuspenseLoading from '@/modules/shared/components/suspense-loading/SuspenseLoading'
// import NotFound from '@/modules/shared/pages/not-found/NotFound'
import ShopRoutes from '@/routes/public-routes/ShopRoutes'

// This PublicRoutes is for only not logged-in users.
export default function PublicRoutes() {
  return (
    <Routes>
      <Route
        path="/shop/*"
        element={
          <SuspenseLoading>
            <ShopRoutes />
          </SuspenseLoading>
        }
      />
      {/* <Route path="*" element={<NotFound />} /> */}
    </Routes>
  )
}
