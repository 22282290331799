import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './public_graphql'

export const GetPublicShopDocument = gql`
  query GetPublicShop($publicShopId: Int!) {
    publicShop(id: $publicShopId) {
      id
      name
      telephone
      billingAddressCity
      billingAddressCountry
      billingAddressIsPostalAddress
      billingAddressLine1
      billingAddressLine2
      billingAddressPostalCode
      billingAddressStateProvince
      billingEmail
      shortDescription
      businessNumber
      shopBannerUrl
      shopHexCode
      shopOverlayLogoUrl
      shopSquareLogoUrl
    }
  }
` as unknown as DocumentNode<Types.GetPublicShopQuery, Types.GetPublicShopQueryVariables>
