import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { GetPublicShopQuery } from '@/graphql/purchase-plus-public/generated/public_graphql'
import { formatSupplierBillingAddress } from '@/modules/requisitions/utils/formatSupplierBillingAddress'
import Button from '@/modules/shared/components/button/Button'
import { Modal } from '@/modules/shared/components/modal/ModalComponent'
import { TableRowSingle } from '@/modules/shared/components/page-header-details-table/TableRowSingle'

interface SupplierInfoModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  supplier: GetPublicShopQuery['publicShop']
}

export default function SupplierInfoModal({ showModal, setShowModal, supplier }: SupplierInfoModalProps) {
  const {
    billingAddressLine1,
    billingAddressLine2,
    billingAddressCity,
    billingAddressStateProvince,
    billingAddressPostalCode,
    name,
    telephone,
    billingEmail,
  } = supplier || {}
  const { t } = useTranslation()

  const onCloseModal = () => {
    setShowModal(false)
  }

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel
        as="div"
        className="flex h-[70vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[37.5rem]"
      >
        <Modal.Title title={t('general.supplierInformation', 'Supplier Information')} onCloseModal={onCloseModal} />
        <Modal.Body className="space-y-4">
          <div>
            <p className="text-sm font-bold">{t('general.supplierInformation', 'Supplier Information')}</p>
            <p className="text-sm text-gray-500">
              {t('publicShops.supplierInformation.description', 'General information about the Supplier')}
            </p>
          </div>
          <div className="w-full divide-y divide-gray-200 overflow-auto rounded-md border border-gray-300">
            <TableRowSingle
              titleText={t('general.supplierName', 'Supplier Name')}
              tooltipText={t('mySuppliers.supplier.details.supplierNameTooltip', 'The name of the Supplier.')}
              testId="name"
              value={name}
            />
            <TableRowSingle
              titleText={t('general.supplierPhone', 'Supplier Phone')}
              tooltipText={t(
                'mySuppliers.supplier.details.supplierPhoneTooltip',
                'The contact phone number of the Supplier.'
              )}
              testId="telephone"
              value={telephone}
            />
            <TableRowSingle
              titleText={t('general.supplierEmail', 'Supplier Email')}
              tooltipText={t(
                'mySuppliers.supplier.details.supplierEmailTooltip',
                'The contact email address of the Supplier.'
              )}
              testId="email"
              value={billingEmail}
            />
            <TableRowSingle
              titleText={t('general.billingAddress', 'Billing Address')}
              tooltipText={t(
                'mySuppliers.supplier.details.billingAddressTooltip',
                'The Billing Address of the Supplier.'
              )}
              value={formatSupplierBillingAddress({
                billingAddressLine1: billingAddressLine1 || null,
                billingAddressLine2: billingAddressLine2 || null,
                billingAddressCity: billingAddressCity || null,
                billingAddressStateProvince: billingAddressStateProvince || null,
                billingAddressPostalCode: billingAddressPostalCode || null,
              })}
              testId="billingAddress"
            />
            {/* <TableRowSingle
              titleText={t('general.postalAddress', 'Postal Address')}
              tooltipText={t(
                'mySuppliers.supplier.details.postalAddressTooltip',
                'The Postal Address of the Supplier.'
              )}
              value={formatSupplierPostalAddress({
                postalAddressLine1: postalAddressLine1 || null,
                postalAddressLine2: postalAddressLine2 || null,
                postalAddressCity: postalAddressCity || null,
                postalAddressStateProvince: postalAddressStateProvince || null,
                postalAddressPostalCode: postalAddressPostalCode || null,
              })}
              testId="postalAddress"
            /> */}
          </div>
        </Modal.Body>
        <Modal.Footer className="flex flex-col-reverse items-center justify-end gap-2 md:flex-row">
          <Button className="h-11 w-full rounded-md bg-gray-200 px-5 text-sm md:w-fit" onClick={() => onCloseModal()}>
            {t('general.close', 'Close')}
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
