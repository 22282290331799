export enum MoreOptionsItem {
  Help,
  Print,
  OpenInPPlus,
  AuditLog,
  InvoiceLink,
  Details,
  AddToBuyList,
  ProductLink,
  ViewRequisition,
  PurchaseOrderLink,
  ResendInvoice,
  AccountConfig,
  RemoveFromBuyList,
  DeleteInvoiceLineItem,
  GlobalManageColumns,
  AllRequisitions,
  Supplier,
  AllBuyLists,
  AllInvoices,
  AllSelfManagedCatalogs,
  AllCreditNotes,
  AllReceivingNotes,
  ReceivingNoteLink,
  AllPurchaseOrders,
  // Add more items as needed here..
}
