import { useTranslation } from 'react-i18next'

import ShopHeader from '@/modules/public-shop/ShopHeader'
import SuppliersList from '@/modules/public-shop/suppliers-list/SuppliersList'
import { Container } from '@/modules/shared/components/container/Container'
import SearchInput from '@/modules/shared/components/search-input/SearchInput'

export default function PublicShop() {
  const { t } = useTranslation()
  return (
    <>
      <ShopHeader />
      <Container layout="md" className="mt-10">
        <SearchInput placeholder={t('general.searchSuppliers', 'Search Suppliers')} />
        <SuppliersList />
      </Container>
    </>
  )
}
