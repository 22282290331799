import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, NavLink, useLocation } from 'react-router-dom'

import { ChevronDownIcon } from '@/modules/shared/icons/ChevronDownIcon'
import { classNames } from '@/modules/shared/utils/classNames'

export type TabItem = {
  target: string
  label: string
  count?: number
  hiddenOnHeaderTabs?: boolean
}

interface HeaderTabProps {
  actionList: TabItem[]
  saveSearch?: boolean
  defaultActiveColor?: boolean
}

function HeaderTabs({ actionList, saveSearch, defaultActiveColor = false }: HeaderTabProps) {
  const { t } = useTranslation()
  const location = useLocation()

  const findActiveRoute = () => {
    const targetArray = location.pathname.split('/')
    return actionList.find((action) => action.target === targetArray[targetArray.length - 1] || action.target === '')
  }

  return (
    <div className="rounded-md bg-white p-4 shadow">
      <div className="hidden flex-wrap gap-2 lg:flex" data-testid="header-tabs">
        {actionList
          .filter((action) => !action.hiddenOnHeaderTabs)
          .map((action) => (
            <NavLink
              end
              key={action.target}
              data-testid={`${action.target}-btn`}
              to={`${action.target}${saveSearch ? location.search : ''}`}
              className={({ isActive }) => {
                return classNames(
                  'flex h-11 items-center gap-x-2 rounded-md px-5 text-sm transition duration-200 hover:brightness-95',
                  {
                    'bg-primary/10 text-primary hover:bg-primary/20': isActive && !defaultActiveColor,
                    'bg-primary text-white hover:brightness-95': isActive && defaultActiveColor,
                    'bg-gray-100': !isActive,
                  }
                )
              }}
            >
              {({ isActive }) => (
                <>
                  <span>{action.label}</span>
                  {(action.count || action.count === 0) && (
                    <div
                      className={classNames(`flex size-5 items-center justify-center rounded-md text-xs text-white`, {
                        'bg-primary': isActive && action.target !== 'flags',
                        'bg-gray-400': !isActive && action.target !== 'flags',
                        'bg-error': action.target === 'flags',
                      })}
                    >
                      {action.count}
                    </div>
                  )}
                </>
              )}
            </NavLink>
          ))}
      </div>
      <div className="block w-full lg:hidden">
        <Menu as="div" className="relative inline-block w-full text-left">
          <Menu.Button className="flex w-full items-center justify-between rounded-md bg-primary/10 p-2 text-sm text-primary">
            <div className="flex items-center gap-x-2">
              <div className="flex h-7 items-center justify-center rounded-md bg-primary/20 p-2">
                {t('general.currentTab', 'Current Tab')}
              </div>
              <p>{findActiveRoute()?.label}</p>
            </div>
            <div className="flex gap-x-2">
              {(findActiveRoute()?.count || findActiveRoute()?.count === 0) && (
                <p className="flex size-5 items-center justify-center rounded-md bg-primary text-xs text-white">
                  {findActiveRoute()?.count}
                </p>
              )}
              <ChevronDownIcon className="size-5" />
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute left-1/2 z-10 mt-2 w-full origin-center -translate-x-1/2 divide-y divide-gray-100 rounded-md border bg-white shadow-lg focus:outline-none">
              {actionList
                .filter((action) => !action.hiddenOnHeaderTabs)
                .map((action) => (
                  <Menu.Item key={action.label}>
                    {({ active }) => (
                      <Link
                        className={classNames(
                          'w-full',
                          'px-4',
                          'py-3',
                          'text-left',
                          'text-sm',
                          'flex',
                          'items-center',
                          'text-gray-700',
                          'transition',
                          'justify-between',
                          {
                            'bg-gray-100 text-gray-900': active,
                          }
                        )}
                        to={`${action.target}${saveSearch ? location.search : ''}`}
                      >
                        <span>{action.label}</span>
                        {(action.count || action.count === 0) && (
                          <div
                            className={classNames(
                              `flex size-5 items-center justify-center rounded-md text-xs text-white`,
                              {
                                'bg-primary': active,
                                'bg-gray-400': !active,
                              }
                            )}
                          >
                            {action.count}
                          </div>
                        )}
                      </Link>
                    )}
                  </Menu.Item>
                ))}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  )
}

export default HeaderTabs
