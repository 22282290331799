import { useTranslation } from 'react-i18next'

import CartSummaryOptions from '@/modules/public-shop/cart/summary/table-view/CartSummaryOptions'
import OrderInstruction from '@/modules/public-shop/cart/summary/table-view/OrderInstruction'
import { useUpdateLocalRequisitionRequisition } from '@/modules/public-shop/hooks/useUpdateLocalRequisition'
import { LocalRequisitionLine } from '@/modules/public-shop/types/requisitionLine'
import { Stepper } from '@/modules/shared/components/stepper/Stepper'
import Table from '@/modules/shared/components/table/Table'
import { MoneyItem } from '@/modules/shared/components/table/table-column-items/shared/MoneyItem'
import { ProductImage } from '@/modules/shared/components/table/table-column-items/shared/ProductImage'
import { TaxPercentage } from '@/modules/shared/components/table/table-column-items/shared/TaxPercentage'

interface CartSummaryTableViewProps {
  lines: LocalRequisitionLine[]
}
export default function CartSummaryTableView({ lines }: CartSummaryTableViewProps) {
  const { t } = useTranslation()
  const { onDeleteRequisitionLine, onUpdateRequisitionLine } = useUpdateLocalRequisitionRequisition()

  const onQuantityChange = (lineId: number, newQuantity: number) => {
    if (newQuantity === 0) {
      onDeleteRequisitionLine(lineId)
    } else {
      onUpdateRequisitionLine({ lineId, quantity: newQuantity })
    }
  }

  return (
    <Table
      dataSource={lines}
      keyExtractor={(record) => String(record.id)}
      hasExtraLine={({ orderInstruction }) => {
        return !!orderInstruction
      }}
      renderExtraLine={(line) => <OrderInstruction line={line} />}
      columns={[
        {
          title: t('general.image', 'Image'),
          key: 'image',
          headerCellStyles: 'pr-0 pl-4 py-3 text-center',
          cellStyles: 'pr-0 pl-4 py-3 text-center',
          minWidth: 4.75,
          maxWidth: 4.75,
          render: ({ product }) => <ProductImage image={String(product?.image)} />,
        },
        {
          title: t('general.product', 'Product'),
          key: 'product',
          headerCellStyles: 'pl-4 py-3',
          cellStyles: 'pl-4 py-3',
          minWidth: 13.75,
          grow: true,
          render: ({ product }) => (
            <span className="space-y-1 text-sm">
              <p className="w-fit break-words leading-5 text-primary">{product.longDescription}</p>
              {/* <span className="text-xs text-gray-500">
                {formatProductItem({
                  itemSize: product?.itemSize || null,
                  itemMeasure: product?.itemMeasure || null,
                  itemPackName: product?.itemPackName || null,
                  itemSellUnit: product?.concatenatedSellUnit || null,
                  itemSellQuantity: product?.itemSellQuantity || null,
                })}
              </span> */}
            </span>
          ),
        },
        {
          title: t('general.quantity', 'Quantity'),
          key: 'quantity',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 12,
          maxWidth: 12,
          alwaysVisible: true,
          render: ({ quantity, id }) => (
            <Stepper
              value={quantity}
              buttonClassName="bg-primary"
              stepperAriaLabel={t('shopPage.stepperAriaLabel', 'Entering number of products')}
              onChange={(newQty: number) => {
                onQuantityChange(id, newQty)
              }}
            />
          ),
        },
        {
          title: t('general.options', 'Options'),
          key: 'options',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 8,
          maxWidth: 8,
          alwaysVisible: true,
          render: (line) => <CartSummaryOptions line={line} />,
        },
        {
          title: t('general.unitPrice', 'Unit Price'),
          key: 'unitPrice',
          headerCellStyles: 'pr-4 py-3 text-right',
          cellStyles: 'pr-4 py-3 text-right',
          minWidth: 6.5,
          maxWidth: 8.5,
          alwaysVisible: true,
          render: ({ unitPrice }) => <MoneyItem value={unitPrice} />,
        },
        {
          title: t('general.tax%', 'Tax %'),
          key: 'tax',
          headerCellStyles: 'pr-4 py-3 text-right',
          cellStyles: 'pr-4 py-3 text-right',
          minWidth: 3.75,
          maxWidth: 3.75,

          render: ({ taxPercentage }) => <TaxPercentage taxPercentage={taxPercentage} />,
        },
        {
          title: t('general.total', 'Total'),
          key: 'total',
          headerCellStyles: 'pr-4 py-3 text-right',
          cellStyles: 'pr-4 py-3 text-right',
          minWidth: 6.5,
          maxWidth: 8.5,
          alwaysVisible: true,
          header: {
            tooltip: t('prSummary.table.totalTooltip', 'The Total of this line, including Tax.'),
          },
          render: ({ lineTotal }) => (
            <strong>
              <MoneyItem value={lineTotal} />
            </strong>
          ),
        },
      ]}
    />
  )
}
