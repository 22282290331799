import { useTranslation } from 'react-i18next'

import { CataloguedProduct } from '@/graphql/purchase-plus-public/generated/public_graphql'
import AddItem from '@/modules/public-shop/components/AddItem'
import { useGetLocalRequisition } from '@/modules/public-shop/hooks/useGetLocalRequisition'
import { Fallback } from '@/modules/shared/components/fallback/Fallback'
import { ImageItem } from '@/modules/shared/components/image/ImageItem'
import { MoreOptionsMenu } from '@/modules/shared/components/more-options/MoreOptionsMenu'
import PriceTax from '@/modules/shared/components/price-tax/PriceTax'
import Table from '@/modules/shared/components/table/Table'
import { HELP_LINK_PUBLIC_SHOP_PRODUCTS_MORE_OPTION } from '@/modules/shared/help'

interface PublicProductListViewProps {
  publicProducts: CataloguedProduct[]
}

export default function PublicProductListView({ publicProducts }: PublicProductListViewProps) {
  const { t } = useTranslation()

  const { requisition } = useGetLocalRequisition()

  return (
    <Table
      dataSource={publicProducts}
      keyExtractor={(record) => String(record.id)}
      columns={[
        {
          title: t('general.image', 'Image'),
          key: 'image',
          headerCellStyles: 'pl-4 pr-0 py-3 text-center',
          cellStyles: 'pl-4 pr-0 py-3 text-center',
          minWidth: 5,
          maxWidth: 5,
          render: (record) => (
            <ImageItem src={record?.image} alt="product" className="size-[3.75rem] border border-gray-200" />
          ),
        },
        {
          title: t('general.item', 'Item'),
          key: 'item',
          headerCellStyles: 'pl-4 py-3',
          cellStyles: 'pl-4 py-3',
          minWidth: 17.5,
          maxWidth: 18.75,
          grow: true,
          render: ({ longDescription }) => (
            <span className="space-y-1 text-sm">
              <p className="w-fit break-words leading-5 text-primary">{longDescription}</p>
              {/* <span className="text-xs text-gray-500">
                {formatProductItem({
                  itemSize: product?.itemSize || null,
                  itemMeasure: product?.itemMeasure || null,
                  itemPackName: product?.itemPackName || null,
                  itemSellUnit: product?.concatenatedSellUnit || null,
                  itemSellQuantity: product?.itemSellQuantity || null,
                })}
              </span> */}
            </span>
          ),
        },
        {
          title: t('general.supplierCode', 'Supplier Code'),
          key: 'supplierCode',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'text-center px-2 py-3',
          minWidth: 6.5,
          maxWidth: 6.5,
          render: ({ supplierProductCode }) => (
            <Fallback condition={!!supplierProductCode}>{supplierProductCode}</Fallback>
          ),
        },
        {
          title: t('general.P+Code', 'P+ Code'),
          key: 'p+code',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'text-center px-2 py-3',
          minWidth: 5,
          maxWidth: 5,
          render: ({ id }) => id,
        },
        {
          title: t('general.unitPrice', 'Unit Price'),
          key: 'unitPrice',
          headerCellStyles: 'pr-4 py-3 text-right',
          cellStyles: 'min-w-[6.875rem] max-w-[9.375rem] pr-4 py-3 text-right',
          minWidth: 6.875,
          maxWidth: 9.375,
          alwaysVisible: true,
          render: ({ sellUnitPrice, sellUnitTaxPercentage }) => (
            <PriceTax unitPrice={sellUnitPrice} taxPercentage={sellUnitTaxPercentage} />
          ),
        },
        {
          title: t('general.quantity', 'Quantity'),
          key: 'addItem',
          headerCellStyles: 'py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 11.25,
          maxWidth: 11.25,
          alwaysVisible: true,

          render: (record) => (
            <div className="flex items-center justify-center">
              <AddItem publicProduct={record} lines={requisition.lines} />
            </div>
          ),
        },
        {
          title: t('general.more', 'More'),
          key: 'more',
          headerCellStyles: ' py-3 text-center',
          cellStyles: 'py-3 text-center',
          minWidth: 4.375,
          maxWidth: 4.375,
          alwaysVisible: true,
          render: () => <MoreOptionsMenu helpLink={HELP_LINK_PUBLIC_SHOP_PRODUCTS_MORE_OPTION} />,
        },
      ]}
    />
  )
}
