import { autoUpdate, useFloating } from '@floating-ui/react-dom'
import { Popover, Transition } from '@headlessui/react'
import { Fragment, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import { ReceivingDocumentStatusEnum } from '@/graphql/purchasing/generated/purchasing_graphql'
import Button from '@/modules/shared/components/button/Button'
import { buttonThemeStyle } from '@/modules/shared/components/more-options/buttonThemeStyle'
import { openInEnterpriseURL } from '@/modules/shared/components/more-options/openInEnterpriseURL'
import { PPNewDetailLink } from '@/modules/shared/components/ppnew-detail-link/PPNewDetailLink'
import useGlobalManageColumns from '@/modules/shared/components/table/hooks/useGlobalManageColumns'
import { Tooltip } from '@/modules/shared/components/tooltip/Tooltip'
import { SUPPORT_LINK } from '@/modules/shared/help'
import { useWindowSize } from '@/modules/shared/hooks/useWindowSize'
import { ClockIcon } from '@/modules/shared/icons/ClockIcon'
import { GetHelpIcon } from '@/modules/shared/icons/GetHelpIcon'
import { HeartIcon } from '@/modules/shared/icons/HeartIcon'
import { InfoIcon } from '@/modules/shared/icons/InfoIcon'
import { NewTabIcon } from '@/modules/shared/icons/NewTabIcon'
import { OptionIcon } from '@/modules/shared/icons/OptionIcon'
import { PrintIcon } from '@/modules/shared/icons/PrintIcon'
import { SettingsIcon } from '@/modules/shared/icons/SettingsIcon'
import { Portal } from '@/modules/shared/layouts/Portal'
import { ButtonTheme } from '@/modules/shared/types/ButtonTheme'
import { MoreOptionsItem } from '@/modules/shared/types/MoreOptionsItem'

export const MoreOptionsLinkClassNames =
  'flex w-full cursor-pointer items-center px-4 sm:py-2 py-3 text-left text-sm text-gray-700 transition hover:bg-gray-100 hover:text-gray-900'
export const MoreOptionsLinkIconClassNames = 'mr-2 size-5'
export interface MoreOptionsMenuProps extends PropsWithChildren {
  showDetails?: (showModal: boolean) => void
  showAddToBuyList?: () => void
  productId?: string | number
  catalogId?: string | number
  buttonTheme?: ButtonTheme
  requisitionId?: string | number
  buyListId?: string | number
  userId?: string | number
  accessUserId?: string | number
  pricedCatalogId?: string | number
  stockLocationId?: string | number
  invoiceId?: string | number
  creditNoteId?: string | number
  purchaseOrderId?: string | number
  receivingNoteId?: string | number
  supplierId?: string | number
  purchaserId?: string | number
  poDeliveries?: boolean
  receivingNoteState?: ReceivingDocumentStatusEnum
  stockTakeInfo?: { stockLocationId: number; stocktakeId: number }
  showProductModal?: { onClick: () => void; show: boolean }
  showRight?: boolean
  components?: MoreOptionsItem[]
  iconClass?: string
  testId?: string
  helpLink?: string
  hideTooltip?: boolean
  openLegacyLink?: string
}

export function MoreOptionsMenu({
  children,
  showAddToBuyList,
  buttonTheme,
  requisitionId,
  showDetails,
  invoiceId,
  purchaseOrderId,
  productId,
  poDeliveries,
  showProductModal,
  receivingNoteId,
  showRight,
  components,
  iconClass,
  testId,
  helpLink,
  hideTooltip,
  openLegacyLink,
  ...props
}: MoreOptionsMenuProps) {
  const { t } = useTranslation()
  const {
    x,
    y,
    strategy,
    refs: { setReference, setFloating },
  } = useFloating({
    placement: showRight ? 'bottom-start' : 'bottom-end',
    whileElementsMounted: autoUpdate,
  })
  const { isLargeScreen } = useWindowSize()
  const { openGlobalManageColumns } = useGlobalManageColumns()

  return (
    <div className="flex h-full items-center justify-center">
      <Tooltip content={hideTooltip ? '' : t('general.moreOptions', 'More Options')}>
        <Popover className="relative flex items-center justify-center">
          <Popover.Button
            className={buttonThemeStyle(buttonTheme)}
            data-testid={testId || 'more-options-button'}
            ref={setReference}
          >
            <OptionIcon
              className={iconClass ? iconClass : 'size-10 text-gray-500'}
              data-testid={`${testId || 'more-options-button'}-card`}
            />
          </Popover.Button>
          <Portal>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Popover.Panel
                data-testid="more-options-menu"
                className="z-[99999] rounded-md border bg-white shadow-lg"
                ref={setFloating}
                style={{
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                }}
              >
                <div className="py-1">
                  {components?.includes(MoreOptionsItem.AuditLog) && (
                    <Link className={MoreOptionsLinkClassNames} data-testid="audit-log" to="audit-log">
                      <ClockIcon className={MoreOptionsLinkIconClassNames} />
                      {t('general.viewAuditLog', 'View Audit Log')}
                    </Link>
                  )}
                  {components?.includes(MoreOptionsItem.PurchaseOrderLink) && purchaseOrderId && (
                    <PPNewDetailLink
                      type="purchase-order"
                      testId="view-purchase-order"
                      id={String(purchaseOrderId)}
                      className={MoreOptionsLinkClassNames}
                    >
                      <InfoIcon className={MoreOptionsLinkIconClassNames} />
                      {t('general.viewPurchaseOrder', 'View Purchase Order')}
                    </PPNewDetailLink>
                  )}
                  {components?.includes(MoreOptionsItem.ReceivingNoteLink) && purchaseOrderId && receivingNoteId && (
                    <PPNewDetailLink
                      type="receiving-document"
                      testId="view-receiving-document"
                      secondaryId={String(purchaseOrderId)}
                      id={String(receivingNoteId)}
                      className={MoreOptionsLinkClassNames}
                    >
                      <InfoIcon className={MoreOptionsLinkIconClassNames} />
                      {t('general.viewReceivingNote', 'View Receiving Note')}
                    </PPNewDetailLink>
                  )}
                  {invoiceId && components?.includes(MoreOptionsItem.InvoiceLink) && (
                    <PPNewDetailLink
                      type="invoice"
                      testId="view-invoice"
                      id={String(invoiceId)}
                      className={MoreOptionsLinkClassNames}
                    >
                      <InfoIcon className={MoreOptionsLinkIconClassNames} />
                      {t('general.viewInvoice', 'View Invoice')}
                    </PPNewDetailLink>
                  )}
                  {components?.includes(MoreOptionsItem.ViewRequisition) && requisitionId && (
                    <PPNewDetailLink
                      className={MoreOptionsLinkClassNames}
                      type="requisition"
                      id={String(requisitionId)}
                      testId="view-requisition"
                    >
                      <InfoIcon className={MoreOptionsLinkIconClassNames} />
                      {t('general.viewRequisition', 'View Requisition')}
                    </PPNewDetailLink>
                  )}
                  {showProductModal?.show && (
                    <Button onClick={showProductModal.onClick} className={MoreOptionsLinkClassNames}>
                      <InfoIcon className={MoreOptionsLinkIconClassNames} />
                      {t('general.viewProduct', 'View Product')}
                    </Button>
                  )}
                  {components?.includes(MoreOptionsItem.ProductLink) && productId && (
                    <Link
                      className={MoreOptionsLinkClassNames}
                      data-testid="view-product"
                      to={generatePath('/products/:productId/details', {
                        productId: String(productId),
                      })}
                    >
                      <InfoIcon className={MoreOptionsLinkIconClassNames} />
                      {t('general.viewProduct', 'View Product')}
                    </Link>
                  )}
                  {components?.includes(MoreOptionsItem.Print) && (
                    <a
                      className={MoreOptionsLinkClassNames}
                      data-testid="print"
                      href={`${openInEnterpriseURL({
                        requisitionId,
                        invoiceId,
                        purchaseOrderId,
                        components,
                        ...props,
                      })}/print`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PrintIcon className={MoreOptionsLinkIconClassNames} />
                      {t('general.print', 'Print')}
                    </a>
                  )}
                  {components?.includes(MoreOptionsItem.OpenInPPlus) && (
                    <a
                      className={MoreOptionsLinkClassNames}
                      data-testid="view-in-enterprise"
                      href={
                        openLegacyLink ||
                        openInEnterpriseURL({
                          requisitionId,
                          invoiceId,
                          purchaseOrderId,
                          components,
                          poDeliveries,
                          receivingNoteId,
                          ...props,
                        })
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <NewTabIcon className={MoreOptionsLinkIconClassNames} />
                      {t('general.openInLegacyPPlus', 'Open in Legacy P+')}
                    </a>
                  )}
                  {components?.includes(MoreOptionsItem.GlobalManageColumns) && isLargeScreen && (
                    <Button onClick={openGlobalManageColumns} className={MoreOptionsLinkClassNames}>
                      <SettingsIcon className={MoreOptionsLinkIconClassNames} />
                      {t('general.manageColumns', 'Manage Columns')}
                    </Button>
                  )}
                  {showDetails && (
                    <p className={MoreOptionsLinkClassNames} onClick={() => showDetails(true)}>
                      <InfoIcon className={MoreOptionsLinkIconClassNames} />
                      {t('general.viewProductDetails', 'View Product Details')}
                    </p>
                  )}
                  {showAddToBuyList && (
                    <p className={MoreOptionsLinkClassNames} onClick={showAddToBuyList}>
                      <HeartIcon className={MoreOptionsLinkIconClassNames} />
                      {t('moreOptions.addToBuyList', 'Add to Buy List')}
                    </p>
                  )}
                  {children}
                  {components?.includes(MoreOptionsItem.Help) && (
                    <a
                      className={MoreOptionsLinkClassNames}
                      href={helpLink || SUPPORT_LINK}
                      data-testid="view-support-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <GetHelpIcon className={MoreOptionsLinkIconClassNames} />
                      {t('general.getHelp', 'Get Help')}
                    </a>
                  )}
                </div>
              </Popover.Panel>
            </Transition>
          </Portal>
        </Popover>
      </Tooltip>
    </div>
  )
}
