import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './purchasePlus_graphql'

export const GetDashboardStatsDocument = gql`
  query GetDashboardStats {
    dashboardStats {
      invoicesAwaitingApprovalCount
      invoicesFlaggedCount
      purchaseOrdersNotSentCount
      transfersAwaitingApprovalCount
      workflowInvoicesAwaitingApprovalCount
      requisitionsAwaitingApprovalCount
    }
  }
` as unknown as DocumentNode<Types.GetDashboardStatsQuery, Types.GetDashboardStatsQueryVariables>
