import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import useSessionUser from '@/modules/access/hooks/useSessionUser'
import useSignOut from '@/modules/access/hooks/useSignout'
import { Button } from '@/modules/shared/components/button/Button'
import useFindMatchRoutes from '@/modules/shared/hooks/useFindMatchRoutes'
import { ArrowsRightLeftIcon } from '@/modules/shared/icons/ArrowsRightLeftIcon'
import { SignOutIcon } from '@/modules/shared/icons/SignOutIcon'
import SwitchOrganisationModal from '@/modules/shared/layouts/header/switch-organisation-modal/SwitchOrganisationModal'
import { HelpCenterSideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/HelpCenterSideBarItem'
import { SideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/PurchaserNavigation'
import { SystemSettingsSideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/SystemSettingsSideBarItem'

export const AccountSideBarItems = ({
  openAccordionKey,
  changeOpenAccordionKey,
  onCloseDrawer,
}: Omit<SideBarItem, 'sideBarIndexKey'>) => {
  const { t } = useTranslation()
  const [signOut, { loading: actionLoading }] = useSignOut()
  const [openSwitchOrg, setOpenSwitchOrg] = useState(false)
  const { userPermissions } = useSessionUser()
  const { systemSettingRoutesMatch, sideBarIndexKeys } = useFindMatchRoutes()

  return (
    <section className="mt-7 space-y-1.5">
      <h1 className="mb-2 px-3 text-sm text-gray-500">{t('general.account', 'Account')}</h1>
      <HelpCenterSideBarItem
        openAccordionKey={openAccordionKey}
        changeOpenAccordionKey={changeOpenAccordionKey}
        onCloseDrawer={onCloseDrawer}
      />
      {userPermissions?.CAN_MANAGE_ORGANISATIONS && (
        <SystemSettingsSideBarItem
          systemSettingRoutesMatch={systemSettingRoutesMatch}
          openAccordionKey={openAccordionKey}
          changeOpenAccordionKey={changeOpenAccordionKey}
          onCloseDrawer={onCloseDrawer}
          sideBarIndexKey={sideBarIndexKeys.systemSettingSideBarKey}
        />
      )}
      <Button
        data-testid="switch-org-btn"
        className="flex h-11 w-full items-center gap-x-2 rounded-md bg-gray-200 px-3"
        onClick={() => setOpenSwitchOrg(true)}
      >
        <ArrowsRightLeftIcon className="size-7" />
        {t('general.switchOrganisation', 'Switch Organisation')}
      </Button>
      <Button
        data-testid="signout-btn"
        loading={actionLoading}
        className="flex h-11 w-full items-center gap-x-2 rounded-md bg-gray-200 px-3"
        onClick={() => signOut()}
      >
        <SignOutIcon className="size-7" />
        {t('general.signOut', 'Sign Out')}
      </Button>
      <SwitchOrganisationModal showModal={openSwitchOrg} setShowModal={setOpenSwitchOrg} />
    </section>
  )
}
