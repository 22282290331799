import { ReactNode, useEffect, useRef, useState } from 'react'

import { classNames } from '@/modules/shared/utils/classNames'

export interface ScrollShadowWrapperProps {
  children: ReactNode
  className?: string
  style?: React.CSSProperties
  direction?: 'horizontal' | 'vertical'
  dragScrollingEnabled?: boolean
  testId?: string
  id?: string
}

export function ScrollShadowWrapper({
  children,
  className = '',
  style = {},
  direction = 'vertical',
  dragScrollingEnabled,
  testId,
  id,
}: ScrollShadowWrapperProps) {
  const [scrollStart, setScrollStart] = useState(0)
  const [scrollSize, setScrollSize] = useState(0)
  const [clientSize, setClientSize] = useState(0)

  // controls for click and drag scrolling.
  const [isDown, setIsDown] = useState(false)
  const [startX, setStartX] = useState(0)

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const ref = wrapperRef
    if (!ref.current) return
    setIsDown(true)
    setStartX(e.pageX - ref.current.offsetLeft)
  }

  const onMouseLeave = () => {
    setIsDown(false)
  }

  const onMouseUp = () => {
    setIsDown(false)
  }

  const onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const ref = wrapperRef
    if (!isDown || !ref.current || !dragScrollingEnabled) return
    e.preventDefault()
    const x = e.pageX - ref.current.offsetLeft
    const walk = (x - startX) / 10 // slow the scroll
    ref.current.scrollLeft = scrollStart - walk
    setScrollStart(ref.current.scrollLeft)
  }

  const onScrollHandler = (event: React.WheelEvent<HTMLDivElement>) => {
    if (direction === 'vertical') {
      setScrollStart(event.currentTarget.scrollTop)
      setScrollSize(event.currentTarget.scrollHeight)
      setClientSize(event.currentTarget.clientHeight)
    } else {
      setScrollStart(event.currentTarget.scrollLeft)
      setScrollSize(event.currentTarget.scrollWidth)
      setClientSize(event.currentTarget.clientWidth)
    }
  }

  const wrapperRef = useRef<HTMLDivElement>(null)

  const resetRefSizes = () => {
    const ref = wrapperRef
    if (!ref.current) return

    if (direction === 'vertical') {
      setScrollStart(ref.current.scrollTop)
      setScrollSize(ref.current.scrollHeight)
      setClientSize(ref.current.clientHeight)
    } else {
      setScrollStart(ref.current.scrollLeft)
      setScrollSize(ref.current.scrollWidth)
      setClientSize(ref.current.clientWidth)
    }
  }

  useEffect(() => {
    resetRefSizes()

    // Watch the window resize event
    window.addEventListener('resize', () => {
      // If the div is not scrollable, reset the heights
      if (
        wrapperRef.current &&
        ((direction === 'vertical' && wrapperRef.current.scrollHeight <= wrapperRef.current.clientHeight) ||
          (direction === 'horizontal' && wrapperRef.current.scrollWidth <= wrapperRef.current.clientWidth))
      ) {
        setScrollSize(0)
        setClientSize(0)
      } else {
        // Otherwise, recalculate the heights
        resetRefSizes()
      }
    })

    // Clean up the event listener
    return () => window.removeEventListener('resize', resetRefSizes)
  }, [])

  const getVisibleSides = (): { start: boolean; end: boolean } => {
    const isEnd = clientSize === scrollSize - scrollStart
    const isStart = scrollStart === 0
    const isBetween = scrollStart > 0 && clientSize < scrollSize - scrollStart

    return {
      start: (isEnd || isBetween) && !(isStart && isEnd),
      end: (isStart || isBetween) && !(isStart && isEnd),
    }
  }

  return (
    <>
      {direction === 'horizontal' ? (
        <div className="relative">
          <div
            data-testid="scroll-shadow-left"
            className={classNames(
              'duration-300$ pointer-events-none absolute inset-y-0 left-0 z-[9] -mr-4 h-full w-4 bg-scroll-shadow-left transition-opacity',
              getVisibleSides().start ? 'opacity-100' : 'opacity-0'
            )}
          />
          <div
            data-testid={testId || 'scroll-shadow-wrapper'}
            id={id || 'scroll-shadow-wrapper'}
            ref={wrapperRef}
            style={style}
            className={classNames('relative overflow-x-auto overflow-y-hidden', className, {
              'cursor-move': !!dragScrollingEnabled && (getVisibleSides().end || getVisibleSides().start),
            })}
            onScroll={onScrollHandler}
            onMouseDown={onMouseDown}
            onMouseLeave={onMouseLeave}
            onMouseUp={onMouseUp}
            onMouseMove={onMouseMove}
          >
            {children}
          </div>
          <div
            data-testid="scroll-shadow-right"
            className={classNames(
              'pointer-events-none absolute inset-y-0 right-0 -ml-4 h-full w-4 bg-scroll-shadow-right transition-opacity duration-300',
              getVisibleSides().end ? 'opacity-100' : 'opacity-0'
            )}
          />
        </div>
      ) : (
        <div
          data-testid={testId || 'scroll-shadow-wrapper'}
          id={id || 'scroll-shadow-wrapper'}
          ref={wrapperRef}
          style={style}
          className={classNames('relative overflow-y-auto pr-px', className)}
          onScroll={onScrollHandler}
        >
          <div
            data-testid="scroll-shadow-up"
            className={classNames(
              'pointer-events-none sticky top-0 z-10 -mb-4 h-4 w-full bg-scroll-shadow-up transition-opacity duration-300',
              getVisibleSides().start ? 'opacity-100' : 'opacity-0'
            )}
          />
          {children}
        </div>
      )}
    </>
  )
}

export default ScrollShadowWrapper
