import { Maybe } from '@/graphql/purchasing/generated/purchasing_graphql'
import { useMoney } from '@/modules/shared/hooks/useMoney'

export interface MoneyItemProps {
  value: Maybe<number>
  currency?: string
}

export function MoneyItem({ value, currency }: MoneyItemProps) {
  const { formatMoney } = useMoney()

  return <>{formatMoney({ amount: value, customCurrency: currency })}</>
}
