import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import LogoAStore from '@/assets/astore/logo.svg'
import LogoPPlus from '@/assets/pplus/logo.svg'
import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { Button } from '@/modules/shared/components/button/Button'
import { ENTERPRISE_SIGN_IN_PAGE } from '@/modules/shared/constants'
import { ChevronDownIcon } from '@/modules/shared/icons/ChevronDownIcon'
import MenuIcon from '@/modules/shared/icons/MenuIcon'
import { UserIcon } from '@/modules/shared/icons/UserIcon'
import { ElementSwitch } from '@/modules/shared/layouts/ElementSwitch'
import UserDetails from '@/modules/shared/layouts/header/UserDetails'
import AboutModal from '@/modules/shared/layouts/header/about/AboutModal'
import SwitchOrganisationModal from '@/modules/shared/layouts/header/switch-organisation-modal/SwitchOrganisationModal'

interface HeaderProps {
  setDrawerOpen?: Dispatch<SetStateAction<boolean>>
  toggleSidebar?: boolean
  setToggleSidebar?: Dispatch<SetStateAction<boolean>>
  showSidebarButtons?: boolean
}

export function Header(props: HeaderProps) {
  const { setDrawerOpen, toggleSidebar = true, showSidebarButtons = false, setToggleSidebar } = props
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const { currentOrganisation, user, loading } = useSessionUser()
  const [openSwitchOrg, setOpenSwitchOrg] = useState(false)
  const [showAboutModal, setShowAboutModal] = useState(false)

  const isAuthenticated = user && currentOrganisation && !loading
  const showUtilitiesWarning = pathname.startsWith('/utilities')

  return (
    <header className="fixed top-0 z-10 flex w-full items-center justify-between border-b bg-white px-4 py-3">
      <div className="h-11">
        <div className="flex items-center justify-center gap-x-3">
          {showSidebarButtons && (
            <>
              <Button
                data-testid="open-drawer-btn"
                className="flex h-11 items-center justify-between gap-6 rounded-md bg-gray-100 px-3 py-1 xl:hidden"
                onClick={() => setDrawerOpen?.(true)}
              >
                <MenuIcon className="size-7 rotate-180" />
              </Button>
              <Button
                data-testid="toggle-sidebar-btn"
                className="hidden h-11 items-center justify-between space-x-2 rounded-md bg-gray-100 px-3 py-1 xl:flex"
                onClick={() => setToggleSidebar?.(!toggleSidebar)}
              >
                <MenuIcon className="size-7 rotate-180" />
                <p className="text-sm">{t('general.menu', 'Menu')}</p>
              </Button>
            </>
          )}
          {!loading && (
            <a href="/">
              <ElementSwitch
                aStoreElement={<img src={LogoAStore} className="h-full" alt="astore" />}
                defaultElement={<img src={LogoPPlus} className="h-full" alt="purchase-plus" />}
              />
            </a>
          )}
        </div>
      </div>
      {showUtilitiesWarning && (
        <div className="rounded-md bg-error/10 px-4 py-3 text-center text-sm text-error">
          {t('header.utilitiesWarning', 'Be careful, you are in a P+ Administrator only area.')}
        </div>
      )}
      <nav className="flex">
        {!isAuthenticated && (
          <a
            className="rounded-md bg-primary px-6 py-3 text-sm text-white hover:opacity-95"
            data-testid="signin-link"
            href={ENTERPRISE_SIGN_IN_PAGE}
          >
            {t('general.signIn', 'Sign In')}
          </a>
        )}
        {isAuthenticated && (
          <>
            <UserDetails setOpenSwitchOrg={setOpenSwitchOrg} setShowAboutModal={setShowAboutModal}>
              <div className="flex flex-1 items-center">
                <UserIcon className="size-7" />
                <div className="max-w-40 pl-2 pr-6 text-left md:max-w-80">
                  <p className="truncate text-xxs font-semibold">{user.fullName}</p>
                  <p className="truncate text-xs text-primary">{currentOrganisation.name}</p>
                </div>
              </div>
              <ChevronDownIcon className="size-6" aria-hidden="true" />
            </UserDetails>
            <AboutModal showModal={showAboutModal} setShowModal={setShowAboutModal} />
            <SwitchOrganisationModal showModal={openSwitchOrg} setShowModal={setOpenSwitchOrg} />
          </>
        )}
      </nav>
    </header>
  )
}
