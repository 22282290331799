import { LOCAL_REQUISITION_KEY } from '@/modules/public-shop/hooks/useCreateLocalRequisition'
import { LocalRequisition } from '@/modules/public-shop/types/requisitionLine'
import useOrganisationId from '@/modules/shared/hooks/params/useOrganisationId'
import { useLocalStorage } from '@/modules/shared/utils/useLocalStorage'

export function useGetLocalRequisition() {
  const organisationId = useOrganisationId()
  const [localRequisition] = useLocalStorage<LocalRequisition>(`${LOCAL_REQUISITION_KEY}-${organisationId}`, {
    lines: [],
    totalTaxValue: 0,
    totalValue: 0,
  })

  return { requisition: localRequisition }
}
