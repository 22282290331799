import { Fragment, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/modules/shared/components/button/Button'
import { Modal } from '@/modules/shared/components/modal/ModalComponent'
import { CheckAltIcon } from '@/modules/shared/icons/CheckAltIcon'
import { ExclamationCircleIcon } from '@/modules/shared/icons/ExclamationCircleIcon'
import AlertContext from '@/modules/shared/providers/AlertProvider'
import { classNames } from '@/modules/shared/utils/classNames'

function AlertDialog() {
  const { t } = useTranslation()
  const alert = useContext(AlertContext)

  const renderIcon = () => {
    switch (alert?.alertType) {
      case 'success':
        return <CheckAltIcon className="h-5 text-success" />
      case 'error':
        return <ExclamationCircleIcon className="h-8 text-error" />
      default:
        return <ExclamationCircleIcon className="h-8 text-primary" />
    }
  }

  return (
    <Fragment>
      {alert && (
        <Modal showModal={alert.show} onCloseModal={() => alert.clear()} dataTestId="alert-dialog">
          <Modal.Panel className="z-50 flex w-full max-w-md flex-col items-center overflow-hidden rounded-2xl bg-white p-6 text-center shadow-xl transition-all">
            <div
              className={classNames(
                'flex',
                'h-12',
                'w-12',
                'items-center',
                'justify-center',
                'rounded-full',
                'bg-opacity-[0.15]',
                {
                  'bg-success': alert.alertType === 'success',
                  'bg-error': alert.alertType === 'error',
                  'bg-primary': alert.alertType === 'primary',
                }
              )}
            >
              {renderIcon()}
            </div>
            <Modal.Title
              title={alert.title || ''}
              className="mt-5 justify-center border-none p-0 text-lg font-medium leading-6 text-gray-900"
            />

            <div className="mt-2">
              <p className="text-sm text-gray-500">{alert.message}</p>
            </div>
            {alert.errorCode && (
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {t('alert.error.errorCode', 'Error Code: {{ code }}', {
                    code: alert.errorCode,
                  })}
                </p>
              </div>
            )}
            <div className="mt-4 w-full">
              <Button
                type="button"
                data-testid="alert-close-button"
                className={classNames(
                  'inline-flex',
                  'w-full',
                  'justify-center',
                  'rounded-md',
                  'border',
                  'border-transparent',
                  'px-4',
                  'py-2',
                  'text-sm',
                  'text-white',
                  {
                    'bg-success': alert.alertType === 'success',
                    'bg-error': alert.alertType === 'error',
                    'bg-primary': alert.alertType === 'primary',
                  }
                )}
                onClick={() => {
                  if (alert.onButtonClick) {
                    alert.onButtonClick()
                  } else {
                    alert.clear()
                  }
                }}
              >
                {alert.buttonText}
              </Button>
            </div>
          </Modal.Panel>
        </Modal>
      )}
    </Fragment>
  )
}

export default AlertDialog
