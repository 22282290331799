import { CataloguedProduct } from '@/graphql/purchase-plus-public/generated/public_graphql'
import AddItem from '@/modules/public-shop/components/AddItem'
import { LocalRequisitionLine } from '@/modules/public-shop/types/requisitionLine'
import { ImageItem } from '@/modules/shared/components/image/ImageItem'
import PriceTax from '@/modules/shared/components/price-tax/PriceTax'
import { classNames } from '@/modules/shared/utils/classNames'

interface PublicProductCardProps {
  publicProduct: CataloguedProduct
  lines: LocalRequisitionLine[]
}

export default function PublicProductCard({ publicProduct, lines }: PublicProductCardProps) {
  const { sellUnitPrice, sellUnitTaxPercentage, image, longDescription } = publicProduct
  return (
    <div data-testid="product-details-card" className="flex flex-col rounded-md border bg-white shadow-sm">
      <div
        className="flex min-h-[8.06rem] grow cursor-pointer items-center border-b border-gray-200 p-3 sm:px-5 sm:py-4"
        data-testid="tileview-clickabke-area"
      >
        <ImageItem
          src={image}
          alt={longDescription || ''}
          className="mr-5 size-[4.25rem] flex-none border border-gray-200 sm:size-24"
        />
        <div className={classNames('overflow-hidden md:w-64', { 'flex grow': !image })}>
          <span className="space-y-1 text-sm">
            <p className="w-fit break-words leading-5 text-primary">{longDescription}</p>
            {/* <span className="text-xs text-gray-500">
              {formatProductItem({
                itemSize: itemSize || null,
                itemMeasure: itemMeasure || null,
                itemPackName: itemPackName || null,
                itemSellUnit: concatenatedSellUnit || null,
                itemSellQuantity: itemSellQuantity || null,
              })}
            </span> */}
          </span>
        </div>
      </div>
      <div className="p-3 md:px-5 md:py-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between">
            <PriceTax unitPrice={sellUnitPrice} taxPercentage={sellUnitTaxPercentage} />
          </div>
          <div className="flex items-center">
            <AddItem publicProduct={publicProduct} lines={lines} />
          </div>
        </div>
      </div>
    </div>
  )
}
