import { useQuery } from '@apollo/client'

import { GetSessionUserDocument } from '@/graphql/purchase-plus/generated/getSessionUser.generated'
import { UserAccessControlList } from '@/modules/access/types'
import { PURCHASE_PLUS_GRAPHQL_API } from '@/modules/shared/constants'
import isPublicRoutes from '@/modules/shared/utils/isPublicRoutes'

interface UseSessionUser {
  skip?: boolean
}

export default function useSessionUser(args?: UseSessionUser) {
  const { skip = false } = args || {}
  const { data, loading, error, refetch } = useQuery(GetSessionUserDocument, {
    context: {
      uri: PURCHASE_PLUS_GRAPHQL_API,
    },
    skip: skip || isPublicRoutes(),
  })

  return {
    user: data?.currentUser,
    userPermissions: data?.currentUser?.accessControlList as UserAccessControlList,
    currentOrganisation: data?.currentOrganisationUnit,
    currentLegalEntity: data?.currentLegalEntity,
    currentAccountsPayableSystem: data?.currentAccountsPayableSystem,
    currentHolder: data?.currentHolder,
    appVersion: { be: data?.backendVersion, fe: import.meta.env.VITE_APP_VERSION },
    loading,
    error,
    refetch,
  }
}
