import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { Accordion } from '@/modules/shared/components/accordion/Accordion'
import { SettingsIcon } from '@/modules/shared/icons/SettingsIcon'
import SideNavLink, { SideNavLinkItem } from '@/modules/shared/layouts/side-navigation/SideNavLink'
import { SideBarItem } from '@/modules/shared/layouts/side-navigation/purchaser-navigation/PurchaserNavigation'
import { Themes } from '@/modules/shared/types/Themes'

interface SystemSettingsSideBarItemProps extends SideBarItem {
  systemSettingRoutesMatch: boolean
}

export const SystemSettingsSideBarItem = ({
  systemSettingRoutesMatch,
  openAccordionKey,
  changeOpenAccordionKey,
  onCloseDrawer,
  sideBarIndexKey,
}: SystemSettingsSideBarItemProps) => {
  const { t } = useTranslation()
  const { userPermissions, currentOrganisation } = useSessionUser()

  const getSideNavLinkItems = () => {
    const sideNavItems: SideNavLinkItem[] = []
    if (userPermissions?.CAN_MANAGE_ORGANISATIONS) {
      sideNavItems.push({
        key: t('general.organisationSettings', 'Organisation Settings'),
        target: generatePath('/settings/organisations/:organisationId/general', {
          organisationId: String(currentOrganisation?.id),
        }),
      })
    }
    if (currentOrganisation?.isAPurchaser && userPermissions?.PURCHASER_CAN_MANAGE_AP_SYSTEM) {
      sideNavItems.push({
        key: t('general.apSystem', 'AP System'),
        target: '/settings/ap-system/general',
      })
    }
    if (
      currentOrganisation?.featureFlags?.newApprovalWorkflows &&
      userPermissions?.PURCHASER_CAN_MANAGE_WORKFLOWS &&
      currentOrganisation?.isAPurchaser
    ) {
      sideNavItems.push({
        key: t('general.approvalWorkflows', 'Approval Workflows'),
        target: '/settings/approval-workflows',
      })
    }

    return sideNavItems
  }

  return (
    <Accordion
      titlePrefixIcon={<SettingsIcon className="size-7" />}
      title={t('general.systemSettings', 'System Settings')}
      activeColor={systemSettingRoutesMatch ? Themes.Primary : undefined}
      defaultOpen={openAccordionKey === sideBarIndexKey}
      handleTriggerClick={() => changeOpenAccordionKey(sideBarIndexKey)}
      buttonClassName="min-h-11 px-3"
      keepActiveColor
    >
      <SideNavLink
        onCloseDrawer={() => onCloseDrawer()}
        testId="systemSettings-navigation"
        sideNavLinkItems={getSideNavLinkItems()}
      />
    </Accordion>
  )
}
