import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddOrderInstructionModal from '@/modules/public-shop/cart/summary/table-view/AddOrderInstructionModal'
import { useUpdateLocalRequisitionRequisition } from '@/modules/public-shop/hooks/useUpdateLocalRequisition'
import { LocalRequisitionLine } from '@/modules/public-shop/types/requisitionLine'
import ExtraLine from '@/modules/shared/components/table/ExtraLine'
import { Themes } from '@/modules/shared/types/Themes'

interface OrderInstructionProps {
  line: LocalRequisitionLine
}

export default function OrderInstruction({ line }: OrderInstructionProps) {
  const { t } = useTranslation()
  const [openOrderInstructionModal, setOpenOrderInstructionModal] = useState(false)
  const { onUpdateRequisitionLine } = useUpdateLocalRequisitionRequisition()
  return (
    <>
      <ExtraLine
        theme={Themes.Primary}
        dataTestId="order-instruction-block"
        removeActionTestId="remove-order-instruction-btn"
        title={t('general.orderInstruction', 'Order Instruction')}
        value={line.orderInstruction || ''}
        showRemoveLineAction
        removeLineTooltipContent={t(
          'shopPage.cart.summary.table.removeOrderInstructionTooltipContent',
          'Remove Order Instruction'
        )}
        onLineClick={() => {
          setOpenOrderInstructionModal(true)
        }}
        onRemoveLine={() => {
          onUpdateRequisitionLine({
            lineId: line.id,
            orderInstruction: null,
          })
        }}
      />
      <AddOrderInstructionModal
        setShowModal={setOpenOrderInstructionModal}
        showModal={openOrderInstructionModal}
        line={line}
      />
    </>
  )
}
