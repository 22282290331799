import i18next from 'i18next'
import validateCurrencyCode from 'validate-currency-code'

import { Supplier } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import { Maybe } from '@/graphql/purchasing/generated/purchasing_graphql'
import { selectedLocale } from '@/locales/i18nextConfig'
import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { useCurrentPurchaser } from '@/modules/purchasing/hooks/useCurrentPurchaser'
import { getCurrencyFormatOptions } from '@/modules/shared/utils/getCurrencyFormatOptions'

interface FormatMoneyOptions {
  amount: number | string | null | undefined
  quantity?: number | null
  customCurrency?: Maybe<string>
}

const DEFAULT_CURRENCY_CODE = 'USD'

export const mapCurrencyToLocales = (currency: string | null | undefined) => {
  switch (currency) {
    case 'AUD':
      return 'en-AU'
    case 'GBP':
      return 'en-GB'
    case 'THB':
      return 'th-TH'
    case 'SGD':
      return 'en-SG'
    case 'FJD':
      return 'en-FJ'
    case 'EUR':
      return 'en-DE'
    case 'KHR':
      return 'km-KH'
    case 'LAK':
      return 'lo-LA'
    case 'MVR':
      return 'dv-MV'
    case 'NZD':
      return 'en-NZ'
    case 'MYR':
      return 'ms-MY'
    case 'PGK':
      return 'en-PG'
    case 'PHP':
      return 'en-PH'
    case 'VND':
      return 'vi-VN'
    case 'VUV':
      return 'bi-VU'
    case 'AED':
      return 'ar-AE'
    case 'ZAR':
      return 'en-ZA'
    case 'USD':
      return 'en-US'
    default:
      return 'en-AU'
  }
}

export function useMoney() {
  const { currentPurchaser } = useCurrentPurchaser()
  const { currentOrganisation } = useSessionUser()

  const sessionCurrency = currentOrganisation?.isAPurchaser
    ? (currentPurchaser?.currency ?? DEFAULT_CURRENCY_CODE)
    : ((currentOrganisation as Supplier)?.defaultCurrency ?? DEFAULT_CURRENCY_CODE)

  const formatMoney = (formatOptions: FormatMoneyOptions) => {
    let { amount = 0 } = formatOptions
    const { quantity = 1, customCurrency } = formatOptions
    if (isNaN(Number(amount))) amount = 0

    const currency = customCurrency || sessionCurrency

    const currentCurrency = validateCurrencyCode(String(currency)) ? String(currency) : DEFAULT_CURRENCY_CODE
    // narrowSymbol is not supported by some older browsers. Falls back to 'symbol' if an error occurs.
    const options = getCurrencyFormatOptions(currentCurrency)

    // Create new Intl.NumberFormat instance with the specified options.
    const formatter = new Intl.NumberFormat(selectedLocale(), options)

    // Format the amount multiplied by the quantity.
    let formatted = formatter.format(Number(amount) * (quantity ?? 1))

    // If featureFlags.showCurrencySymbols is not enabled inside currentOrganisation,
    // remove the currency symbol from the formatted string.
    if (currentOrganisation?.featureFlags && !currentOrganisation?.featureFlags?.showCurrencySymbols) {
      // Find the currency symbol.
      const currencySymbol = new Intl.NumberFormat(selectedLocale(), options)
        .formatToParts(0)
        .find((part) => part.type === 'currency')?.value

      // If currency symbol was found, remove it from the formatted string.
      if (currencySymbol) {
        formatted = formatted.replace(currencySymbol, '').trim()
      }
    }

    // Return the final formatted string.
    return formatted
  }

  i18next.services.formatter?.add('formatMoney', (value) => formatMoney({ amount: value }))

  return {
    formatMoney,
    currency: sessionCurrency,
  }
}
