import { gql } from '@apollo/client'

import { BARCODE } from '@/graphql/purchasing/operations/fragments/Barcode'
import { PAGE_INFO } from '@/graphql/purchasing/operations/fragments/PageInfo'

export const GET_CURRENT_SUPPLIER_PRODUCTS = gql`
  ${PAGE_INFO}
  ${BARCODE}
  query GetCurrentSupplierProducts(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchText: String
    $hasImage: Boolean
    $catalogued: Boolean
    $matched: Boolean
    $categoryIds: [Int!]
    $productCode: String
    $productId: Int
    $catalogueId: Int!
    $sort: [RansackSortType!]
    $includeCataloguedProducts: Boolean
    $externalId: String
  ) {
    currentLegalEntity {
      id
      ... on Supplier {
        availableProducts(
          after: $after
          before: $before
          first: $first
          last: $last
          searchText: $searchText
          hasImage: $hasImage
          catalogued: $catalogued
          matched: $matched
          categoryIds: $categoryIds
          productCode: $productCode
          productId: $productId
          sort: $sort
          includeCataloguedProducts: $includeCataloguedProducts
          externalId: $externalId
        ) {
          edges {
            node {
              id
              master
              productCode
              image
              brand
              itemDescription
              itemSize
              itemMeasure
              itemPackName
              externalId
              concatenatedSellUnit
              catalogued(catalogueId: $catalogueId)
              barcodes {
                edges {
                  node {
                    ...PurchasingBarcode
                  }
                }
              }
              availableQuotes {
                supplier {
                  id
                  name
                }
                catalogue {
                  id
                }
                unitPrice
                taxPercentage
                contracted
              }
              catalogues {
                edges {
                  node {
                    id
                    title
                  }
                }
              }
              category {
                id
                name
                parent {
                  id
                  name
                }
              }
              canonicalProductId
              owner {
                id
                name
              }
              brand
              itemDescription
              itemSize
              itemMeasure
              itemPackName
              concatenatedSellUnit
            }
          }
          pageInfo {
            ...PurchasingPageInfo
          }
        }
      }
    }
  }
`
