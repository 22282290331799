import { Fallback } from '@/modules/shared/components/fallback/Fallback'

export interface TaxPercentageProps {
  taxPercentage: number | null | undefined
  availableQuotes?: boolean
}

export function TaxPercentage({ taxPercentage, availableQuotes = true }: TaxPercentageProps) {
  return (
    <Fallback condition={!!availableQuotes}>
      <p>{`${taxPercentage}%`}</p>
    </Fallback>
  )
}
