import { ReactNode } from 'react'

import TableItem from '@/modules/shared/components/page-header-details-table/TableItem'

interface TableRowSingleProps {
  titleText: string
  tooltipText?: string | JSX.Element
  value: string | ReactNode
  update?: string
  testId: string
  customUpdateText?: string
  valueClassName?: string
}

export function TableRowSingle({
  titleText,
  tooltipText,
  value,
  update,
  testId,
  customUpdateText,
  valueClassName,
}: TableRowSingleProps) {
  return (
    <>
      <div className="flex">
        <div className="flex grow items-center">
          <TableItem
            titleText={titleText}
            tooltipText={tooltipText}
            value={value}
            update={update}
            testId={testId}
            customUpdateText={customUpdateText}
            valueClassName={valueClassName}
          />
        </div>
      </div>
    </>
  )
}
