import { useRef } from 'react'
import { AriaButtonProps, useButton } from 'react-aria'

import { classNames } from '@/modules/shared/utils/classNames'

interface FilterButtonProps {
  className?: string
  isActive?: boolean
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export function FilterButton(props: AriaButtonProps & FilterButtonProps) {
  const ref = useRef(null)
  const { buttonProps } = useButton(props, ref)
  const { children } = props

  return (
    <button
      {...buttonProps}
      ref={ref}
      className={classNames(
        {
          'border-primary bg-primary/10 text-primary': props.isActive,
          'cursor-not-allowed opacity-60': props.disabled,
        },
        props.className
      )}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {children}
    </button>
  )
}
