import { useTranslation } from 'react-i18next'

import { HELP_LINK_PUBLIC_SHOP } from '@/modules/shared/help'
import { ChevronLeftIcon } from '@/modules/shared/icons/ChevronLeftIcon'

export default function ShopHeader() {
  const { t } = useTranslation()
  return (
    <header className="flex items-center justify-between border-b bg-white px-5 py-3">
      <a
        className="flex h-11 w-fit items-center justify-between gap-x-2 rounded-md bg-gray-100 px-4 transition hover:brightness-95"
        href="/"
        data-testid="header-return-to-purchaseplus"
      >
        <ChevronLeftIcon className="-ml-2 size-5" />
        <span className="text-sm">{t('general.returnToPurchasePlus', 'Return to PurchasePlus')}</span>
      </a>
      <a
        className="hidden h-11 w-fit items-center justify-between gap-x-2 rounded-md bg-gray-100 px-4 transition hover:brightness-95 md:flex"
        href={HELP_LINK_PUBLIC_SHOP}
        target="_blank"
        rel="noreferrer"
        data-testid="header-learn-more"
      >
        <span className="text-sm">{t('general.learnMore', 'Learn More')}</span>
      </a>
    </header>
  )
}
