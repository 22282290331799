import { useEffect } from 'react'

import useGetPublicShop from '@/modules/public-shop/hooks/useGetPublicShop'
import { hexToRgb } from '@/modules/shared/utils/hexToRgb'

export function useSetShopPrimaryColor() {
  const { publicShop } = useGetPublicShop()
  useEffect(() => {
    if (publicShop) {
      document.documentElement.style.setProperty('--primary-color', hexToRgb(publicShop?.shopHexCode || '#000000'))
    }
  }, [publicShop])
}
