import { useMutation } from '@apollo/client'
import { produce } from 'immer'

import { CreatePricedCataloguedProductDocument } from '@/graphql/purchase-plus/generated/createPricedCataloguedProduct.generated'
import { GetAvailableProductsQuery, ProductEdge } from '@/graphql/purchasing/generated/purchasing_graphql'
import { GET_AVAILABLE_PRODUCTS } from '@/graphql/purchasing/operations/queries/getAvailableProducts'
import { PURCHASE_PLUS_GRAPHQL_API } from '@/modules/shared/constants'
import { ProductType } from '@/modules/shared/types/ProductType'

interface UseCreatePricedCataloguedProductProps {
  catalogId?: number
  line?: ProductType
}

export default function useCreatePricedCatalogedProduct({ catalogId, line }: UseCreatePricedCataloguedProductProps) {
  const mutation = useMutation(CreatePricedCataloguedProductDocument, {
    context: {
      uri: PURCHASE_PLUS_GRAPHQL_API,
    },
    update: (cache, data) => {
      if (!!line && !data.data?.createPricedCataloguedProduct.errors?.length) {
        const newProduct = data?.data?.createPricedCataloguedProduct?.pricedCataloguedProduct
        const queryToCachePurchaserProducts = {
          query: GET_AVAILABLE_PRODUCTS,
          variables: {
            invoiceId: 0,
            requisitionId: 0,
            catalogueId: catalogId,
            sort: {
              property: 'created_at',
              direction: 'DESC',
            },
          },
        }

        const availablePurchaserProductsCache = cache.readQuery(queryToCachePurchaserProducts)

        const newAvailableProductsCache = produce(
          availablePurchaserProductsCache,
          (draft: GetAvailableProductsQuery) => {
            const { edges } = draft.currentPurchaser?.availableProducts || {}
            if (draft.currentPurchaser && draft.currentPurchaser.availableProducts) {
              draft.currentPurchaser.availableProducts.edges = edges?.map((e) => {
                if (e?.node?.id === line?.product?.id) {
                  if (e && e.node) {
                    e.node.catalogued = true
                    e.node.availableQuotes?.push({
                      catalogue: {
                        id: Number(newProduct?.catalogue.id),
                        title: newProduct?.catalogue?.title || '',
                        __typename: 'Catalogue',
                      },
                      product: {
                        id: Number(newProduct?.product?.id),
                        image: newProduct?.product?.image || '',
                        brand: newProduct?.product?.brand || '',
                        itemDescription: newProduct?.product?.itemDescription || '',
                        itemSize: newProduct?.product?.itemSize || null,
                        itemMeasure: newProduct?.product?.itemMeasure || '',
                        itemPackName: newProduct?.product?.itemPackName || '',
                        concatenatedSellUnit: newProduct?.product?.concatenatedSellUnit || '',
                        productCode: newProduct?.product?.productCode || '',
                        __typename: 'Product',
                      },
                      unitPrice: Number(newProduct?.sellUnitPrice),
                      taxPercentage: Number(newProduct?.sellUnitTaxPercentage),
                      supplier: {
                        id: newProduct?.catalogue?.supplier?.id || 0,
                        name: newProduct?.catalogue?.supplier?.name || '',
                        __typename: 'Supplier',
                      },
                      contracted: false,
                    })
                  }
                }
                return e
              }) as ProductEdge[]
            }
          }
        )
        cache.writeQuery({ ...queryToCachePurchaserProducts, data: newAvailableProductsCache })
      }
    },
  })

  return mutation
}
