import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { HELP_LINK_SUPPLIER_PUBLIC_PRODUCTS } from '@/modules/shared/help'
import { ChevronLeftIcon } from '@/modules/shared/icons/ChevronLeftIcon'

export default function SupplierHeader() {
  const { t } = useTranslation()

  return (
    <header className="flex items-center justify-between border-b bg-white px-5 py-3">
      <Link
        className="flex h-11 w-fit items-center justify-between gap-x-2 rounded-md bg-gray-100 px-4 transition hover:brightness-95"
        to="/shop"
        data-testid="header-return-to-all-suppliers"
      >
        <ChevronLeftIcon className="-ml-2 size-5" />
        <span className="text-sm">{t('general.allSuppliers', 'All Suppliers')}</span>
      </Link>
      <a
        className="hidden h-11 w-fit items-center justify-between gap-x-2 rounded-md bg-gray-100 px-4 transition hover:brightness-95 md:flex"
        href={HELP_LINK_SUPPLIER_PUBLIC_PRODUCTS}
        target="_blank"
        rel="noreferrer"
        data-testid="header-learn-more"
      >
        <span className="text-sm">{t('general.learnMore', 'Learn More')}</span>
      </a>
    </header>
  )
}
