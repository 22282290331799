import { ReactNode, useContext } from 'react'
import { useHover } from 'react-aria'
import { useTranslation } from 'react-i18next'

import { Fallback } from '@/modules/shared/components/fallback/Fallback'
import { Tooltip } from '@/modules/shared/components/tooltip/Tooltip'
import { DetailsContext } from '@/modules/shared/contexts/DetailsContext'
import { QuestionMarkIcon } from '@/modules/shared/icons/QuestionMarkIcon'
import { classNames } from '@/modules/shared/utils/classNames'

interface TableItemProps {
  titleText: string
  tooltipText?: string | JSX.Element
  value: ReactNode
  update?: string
  testId: string
  customUpdateText?: string
  valueClassName?: string
}

function TableItem({
  titleText,
  tooltipText,
  value,
  update,
  testId,
  customUpdateText,
  valueClassName,
}: TableItemProps) {
  const { hoverProps, isHovered } = useHover({})
  const { t } = useTranslation()
  const { setModalType, showUpdateButton, tooltip = true } = useContext(DetailsContext) || {}

  return (
    <>
      <div className="flex h-full w-40 shrink-0 items-center gap-x-1 border-r bg-gray-100 py-3.5 pl-3 text-xs sm:w-48 sm:text-sm lg:w-64">
        {titleText}
        {tooltip && (
          <Tooltip content={tooltipText || ''}>
            <span>
              <QuestionMarkIcon className="size-4" />
            </span>
          </Tooltip>
        )}
      </div>
      <div
        className="relative flex grow flex-wrap gap-x-1 p-3 text-xs sm:text-sm"
        {...hoverProps}
        data-testid={`${testId}-value`}
      >
        <span className={classNames(valueClassName)}>
          <Fallback condition={!!value}>{value}</Fallback>
        </span>
        {update && isHovered && showUpdateButton && (
          <div
            className="absolute inset-y-0 right-2.5 m-auto h-6 cursor-pointer rounded-md bg-gray-100 px-3 py-1 hover:bg-gray-300 sm:h-7"
            onClick={() => setModalType && setModalType(update)}
            data-testid={`${testId}-update-button`}
          >
            {customUpdateText || t('general.update', 'Update')}
          </div>
        )}
      </div>
    </>
  )
}

export default TableItem
