import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PublicPageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './public_graphql'

export const GetPublicShopsDocument = gql`
  query GetPublicShops(
    $after: String
    $before: String
    $filters: PublicShopsQueryFiltersInput
    $first: Int
    $last: Int
    $sorts: [PublicShopsQuerySortsInput!]
  ) {
    publicShops(after: $after, before: $before, filters: $filters, first: $first, last: $last, sorts: $sorts) {
      edges {
        node {
          id
          name
          shortDescription
          shopBannerUrl
          shopHexCode
          shopOverlayLogoUrl
          shopSquareLogoUrl
        }
      }
      pageInfo {
        ...PublicPageInfo
      }
    }
  }
  ${PublicPageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetPublicShopsQuery, Types.GetPublicShopsQueryVariables>
