import { PropsWithChildren, ReactNode, useState } from 'react'
import Collapsible from 'react-collapsible'

import Button from '@/modules/shared/components/button/Button'
import { ChevronDownIcon } from '@/modules/shared/icons/ChevronDownIcon'
import { Themes } from '@/modules/shared/types/Themes'
import { classNames } from '@/modules/shared/utils/classNames'

interface AccordionProps extends PropsWithChildren {
  title: string
  buttonClassName?: string
  titleClassName?: string
  titlePrefixIcon?: ReactNode
  titleSuffixIcon?: ReactNode
  titleEndIcon?: ReactNode
  defaultOpen?: boolean
  activeColor?: Themes
  keepActiveColor?: boolean
  testId?: string
  handleTriggerClick?: () => void
}

export function Accordion({
  title,
  activeColor,
  buttonClassName,
  titleClassName,
  titlePrefixIcon,
  titleSuffixIcon,
  titleEndIcon,
  defaultOpen = false,
  keepActiveColor = false,
  testId,
  children,
  handleTriggerClick,
}: AccordionProps) {
  const [buttonIsOpen, setButtonIsOpen] = useState<boolean>(defaultOpen)

  return (
    <Collapsible
      triggerTagName="div"
      easing="ease-in-out"
      transitionTime={200}
      transitionCloseTime={0}
      open={defaultOpen}
      onOpening={() => setButtonIsOpen(true)}
      onClosing={() => setButtonIsOpen(false)}
      handleTriggerClick={handleTriggerClick}
      trigger={
        <Button
          data-testid={testId || `accordion-${title.toLowerCase().replace(/\s/g, '')}-btn`}
          className={classNames(
            'flex w-full items-center justify-between rounded-md hover:brightness-95',
            {
              'bg-primary text-white': (buttonIsOpen || keepActiveColor) && activeColor === Themes.Primary,
              'bg-gray-200': !activeColor || (buttonIsOpen && !activeColor),
              'bg-gray-100': !keepActiveColor && !buttonIsOpen,
            },
            buttonClassName
          )}
        >
          <div className="flex items-center gap-2">
            <span className={classNames({ 'shrink-0': !!titlePrefixIcon, hidden: !titlePrefixIcon })}>
              {titlePrefixIcon}
            </span>
            <span className={classNames(titleClassName, 'text-left text-sm')}>{title}</span>
            {titleSuffixIcon}
          </div>
          <div className="flex items-center gap-2">
            {titleEndIcon}
            <ChevronDownIcon
              className={classNames('size-5 transition-all duration-300', {
                '-rotate-180 transform': buttonIsOpen,
              })}
            />
          </div>
        </Button>
      }
    >
      <div className="w-full">{children}</div>
    </Collapsible>
  )
}
