import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PurchasePlusPageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetAccountsPayableSystemFlatAccountsDocument = gql`
  query GetAccountsPayableSystemFlatAccounts(
    $accountsPayableSystemId: Int!
    $filters: AccountsQueryFiltersInput
    $after: String
    $before: String
    $first: Int
    $last: Int
    $sorts: [AccountsQuerySortsInput!]
  ) {
    accountsPayableSystem(id: $accountsPayableSystemId) {
      id
      flatAccountCodes(filters: $filters, after: $after, before: $before, first: $first, last: $last, sorts: $sorts) {
        edges {
          node {
            id
            name
            code
            description
            enabled
            accountName: codedName
            children {
              totalCount
            }
            parent {
              id
              name
              code
            }
          }
        }
        pageInfo {
          ...PurchasePlusPageInfo
        }
      }
    }
  }
  ${PurchasePlusPageInfoFragmentDoc}
` as unknown as DocumentNode<
  Types.GetAccountsPayableSystemFlatAccountsQuery,
  Types.GetAccountsPayableSystemFlatAccountsQueryVariables
>
