import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'

import { useSession } from '@/modules/access/hooks/useSession'
import useGetAllOrganisations from '@/modules/admin-center/hooks/useGetAllOrganisations'
import { Spinner } from '@/modules/requisitions/components/spinner/Spinner'
import { Input } from '@/modules/shared/components/input/Input'
import { QueryResult } from '@/modules/shared/components/query-result/QueryResult'
import { useDebounce } from '@/modules/shared/hooks/useDebounce'
import SelectOrganisationAdminList from '@/modules/shared/layouts/header/switch-organisation-modal/SelectOrganisationAdminList'
import SelectOrganisationUserList from '@/modules/shared/layouts/header/switch-organisation-modal/SelectOrganisationUserList'

interface SelectOrganisationProps {
  showModal: boolean
  onSelectOrganisation: Dispatch<SetStateAction<number | null>>
  selectedOrganisation: number | null
}

export default function SelectOrganisation({
  showModal,
  onSelectOrganisation,
  selectedOrganisation,
}: SelectOrganisationProps) {
  const { t } = useTranslation()
  const [searchValue, setSearchValue] = useState('')
  const [inputFocus, setInputFocus] = useState(false)
  const debouncedSearchValue = useDebounce(searchValue, 500)
  const [idSearchValue, setIdSearchValue] = useState('')
  const [idInputFocus, setIdInputFocus] = useState(false)
  const debouncedIdSearchValue = useDebounce(idSearchValue, 500)
  const { currentUser } = useSession()

  const { fetchAllOrganisations, organisations, loadingStates, error, pageInfo, refetch, onFetchMoreOrganisations } =
    useGetAllOrganisations({
      lazyQuery: true,
      ignoreSearchValue: true,
    })

  const onSearch = () => {
    refetch({
      filter: {
        q: [
          {
            property: 'name_cont',
            value: searchValue,
          },
          {
            property: 'id_eq',
            value: idSearchValue,
          },
        ],
      },
    })
  }

  useEffect(() => {
    if (showModal && currentUser?.isAdmin) {
      fetchAllOrganisations()
    }
  }, [showModal])

  useEffect(() => {
    if (inputFocus) {
      onSearch()
    }
  }, [debouncedSearchValue])

  useEffect(() => {
    if (idInputFocus) {
      onSearch()
    }
  }, [debouncedIdSearchValue])

  return (
    <>
      <div className="my-2">
        {currentUser?.isAdmin && (
          <>
            <p className="mb-2 font-bold">
              {t('switchOrganisation.searchByOrganisationName', 'Search By Organisation Name')}
            </p>
            <Input
              data-testid="organisation-search-input"
              className="w-full rounded-md border border-gray-200 p-3 text-sm focus:ring-primary"
              placeholder={t('general.search', 'Search')}
              aria-label={t('general.searchingForOrganizations', 'Searching for organizations')}
              onChange={(e) => setSearchValue(e)}
              onFocus={() => setInputFocus(true)}
            />
            <p className="mb-2 mt-3 font-bold">
              {t('switchOrganisation.searchByOrganisationId', 'Search By Organisation ID')}
            </p>
            <Input
              data-testid="organisation-search-input"
              className="mb-3 w-1/3 rounded-md border border-gray-200 p-3 text-sm focus:ring-primary"
              placeholder={t('general.search', 'Search')}
              aria-label={t('general.searchById', 'Search by ID')}
              onChange={(e) => setIdSearchValue(e)}
              onFocus={() => setIdInputFocus(true)}
            />
          </>
        )}
      </div>
      {currentUser?.isAdmin ? (
        <QueryResult loading={loadingStates.loading || loadingStates.setVariablesLoading} error={error}>
          {organisations.length ? (
            <InfiniteScroll
              dataLength={organisations.length}
              next={onFetchMoreOrganisations}
              hasMore={!!pageInfo?.hasNextPage}
              loader={<Spinner className="mt-5 h-14 md:w-16" />}
              scrollableTarget="InfiniteScroll"
            >
              <SelectOrganisationAdminList organisations={organisations} onSelectOrganisation={onSelectOrganisation} />
            </InfiniteScroll>
          ) : (
            <p className="mt-10 text-center text-gray-500">
              {t('access.switchOrganisation.noOrganisations', 'There are no Organisations found.')}
            </p>
          )}
        </QueryResult>
      ) : (
        <SelectOrganisationUserList
          onSelectOrganisation={onSelectOrganisation}
          selectedOrganisation={selectedOrganisation}
          showModal={showModal}
        />
      )}
    </>
  )
}
