import { PathParam } from 'react-router-dom'

export const ROUTE_PARAMS = {
  REQUISITION: '/:requisitionId',
  PURCHASE_ORDER: '/:purchaseOrderId',
  RECEIVING_NOTE: '/:receivingNoteId',
  INVOICE: '/:invoiceId',
  CREDIT_NOTE: '/:creditNoteId',
  BUY_LIST: '/:buyListId',
  CATALOG: '/:pricedCatalogueId',
  PRODUCT: '/:productId',
  RECIPE: '/:recipeId',
  SUPPLIER_RELATIONSHIP: '/:supplierRelationshipId',
  SUPPLIER: '/:supplierId',
  STOCK_LOCATION: '/:stockLocationId',
  STOCK_ITEM: '/:stockItemId',
  TRANSFER: '/:transferId',
  STOCKTAKE: '/:stocktakeId',
  REPORT: '/:reportId',
  REPORT_NUMBER: '/:reportNumber',
  USER: '/:userId',
  ORGANISATION: '/:organisationId',
  WORKFLOW: '/:workflowId',
  MENU: '/:menuId',
  SCANNING_JOB: '/:scanningJobId',
  AP_EXPORT_FORMAT: '/:apExportFormatId',
  AP_SYSTEM: '/:apSystemId',
  TRADE_RELATIONSHIP: '/:tradeRelationshipId',
  TRADE_PIPE: '/:tradePipeId',
  AI_PROMPT: '/:aiPromptId',
  PURCHASER_RELATIONSHIP: '/:purchaserRelationshipId',
  EXPORT: '/:exportId',
  POS_ITEM: '/:posItemId',
  POS_OUTLET: '/:posOutletId',
  POS_BATCH: '/:posBatchId',
  PUBLIC_ORDER: '/:orderId',
  SHOP: '/:shopId',
  BILLING_GROUP: '/:billingGroupId',
} as const

export type RouteParams = {
  [K in keyof typeof ROUTE_PARAMS]: { [P in PathParam<(typeof ROUTE_PARAMS)[K]>]: string }
}
