import { useEffect, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

import { Input } from '@/modules/shared/components/input/Input'
import { useDebounce } from '@/modules/shared/hooks/useDebounce'
import { MagnifyingGlassIcon } from '@/modules/shared/icons/MagnifyingGlassIcon'
import { QueryParameter } from '@/modules/shared/types/QueryParameter'

interface SearchInputProps {
  placeholder: string
  testId?: string
  ariaLabel?: string
  className?: string
  searchTermURLParamType?: QueryParameter
  defaultValue?: string | null
  type?: string
}

export default function SearchInput({
  placeholder,
  testId,
  ariaLabel,
  className,
  searchTermURLParamType = QueryParameter.Search,
  defaultValue,
  type,
}: SearchInputProps) {
  const [searchValue, setSearchValue] = useState(defaultValue || '')
  const [inputFocus, setInputFocus] = useState(false)
  const debouncedSearchValue = useDebounce(searchValue, 500)

  const [searchTermURLParam, setSearchTermURLParam] = useQueryParam(searchTermURLParamType, StringParam)

  useEffect(() => {
    if (inputFocus) {
      if (debouncedSearchValue === '') {
        setSearchTermURLParam(null)
      } else {
        setSearchTermURLParam(debouncedSearchValue)
      }
    }
  }, [debouncedSearchValue])

  useEffect(() => {
    if (searchTermURLParam) setSearchValue(searchTermURLParam)
  }, [])

  useEffect(() => {
    if (searchTermURLParam !== searchValue) {
      setSearchValue(searchTermURLParam || '')
    }
  }, [searchTermURLParam])

  return (
    <div className={className ?? 'w-full'}>
      <Input
        data-testid={testId || 'search-input'}
        className="w-full rounded-md border border-gray-200 p-3 text-sm focus:ring-primary"
        placeholder={placeholder}
        aria-label={ariaLabel || placeholder}
        value={searchValue}
        onChange={(e) => setSearchValue(e)}
        onPaste={(e) => {
          e.preventDefault()
          setSearchValue(e.clipboardData.getData('text').trim())
        }}
        onFocus={() => setInputFocus(true)}
        suffixIcon={MagnifyingGlassIcon}
        type={type}
      />
    </div>
  )
}
