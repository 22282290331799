import { PropsWithChildren, ReactNode, createContext, useContext, useRef } from 'react'
import { AriaCheckboxGroupItemProps, useCheckboxGroup, useCheckboxGroupItem } from 'react-aria'
import { CheckboxGroupProps, CheckboxGroupState, useCheckboxGroupState } from 'react-stately'

import { CheckboxIcon } from '@/modules/shared/icons-special/CheckboxIcon'

const CheckboxGroupContext = createContext<CheckboxGroupState | null>(null)

function CheckboxGroup(props: CheckboxGroupProps & PropsWithChildren) {
  const { children, label, description, isInvalid, errorMessage } = props
  const state = useCheckboxGroupState(props)
  const { groupProps, labelProps, descriptionProps, errorMessageProps } = useCheckboxGroup(props, state)
  return (
    <div {...groupProps}>
      <span {...labelProps} data-testid="checkbox-group-label">
        {label}
      </span>
      <CheckboxGroupContext.Provider value={state}>{children}</CheckboxGroupContext.Provider>
      {description && <div {...descriptionProps}>{description}</div>}
      {isInvalid && (
        <div {...errorMessageProps} className="text-error" data-testid="checkbox-group-error">
          {errorMessage as ReactNode}
        </div>
      )}
    </div>
  )
}

function CheckboxGroupItem(props: AriaCheckboxGroupItemProps & PropsWithChildren) {
  const { children } = props
  const state = useContext(CheckboxGroupContext)
  const ref = useRef(null)
  const { inputProps } = useCheckboxGroupItem(props, state || ({} as CheckboxGroupState), ref)
  const isSelected = state && state.isSelected(props.value)

  return (
    <label className="flex cursor-pointer items-center gap-2 text-sm">
      <input className="hidden" {...inputProps} ref={ref} />
      <CheckboxIcon isSelected={!!isSelected} />

      {children}
    </label>
  )
}

export { CheckboxGroup, CheckboxGroupItem }
