import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'

import useGetPublicShops from '@/modules/public-shop/hooks/useGetPublicShops'
import SupplierCard from '@/modules/public-shop/suppliers-list/SupplierCard'
import { Spinner } from '@/modules/requisitions/components/spinner/Spinner'
import EmptyState from '@/modules/shared/components/empty-state/EmptyState'
import { QueryResult } from '@/modules/shared/components/query-result/QueryResult'

export default function SuppliersList() {
  const { t } = useTranslation()
  const { publicShops, onFetchMore, pageInfo, loadingStates, error } = useGetPublicShops()
  return (
    <QueryResult loading={loadingStates.loading || loadingStates.setVariablesLoading} error={error}>
      <InfiniteScroll
        dataLength={publicShops.length}
        next={onFetchMore}
        hasMore={!!pageInfo?.hasNextPage}
        loader={<Spinner className="my-5 h-14 md:w-16" />}
      >
        <div className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3">
          {publicShops.map((publicShop) => (
            <SupplierCard
              key={publicShop.id}
              squareLogo={publicShop.shopSquareLogoUrl}
              overlayLogo={publicShop.shopOverlayLogoUrl}
              coverImage={publicShop.shopBannerUrl}
              supplierName={publicShop.name || ''}
              supplierTagLine={publicShop.shortDescription || ''}
              organisationId={String(publicShop.id)}
            />
          ))}
        </div>
      </InfiniteScroll>
      {!loadingStates.setVariablesLoading && publicShops.length === 0 && (
        <EmptyState message={t('publicShops.noSuppliers', 'There are no Suppliers to display.')} />
      )}
      <div className="h-80" />
    </QueryResult>
  )
}
