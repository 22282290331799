// TODO: Remove entirely 'CoverImage', 'OverlayLogo', and 'SquareLogo' when these images exist on BE.
import { Transition } from '@headlessui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router-dom'

import Logo from '@/assets/images/logo.png'
import AdvancedSearch from '@/modules/public-shop/components/advanced-search/AdvancedSearch'
import { useCreateLocalnRequisition } from '@/modules/public-shop/hooks/useCreateLocalRequisition'
import { useGetLocalRequisition } from '@/modules/public-shop/hooks/useGetLocalRequisition'
import useGetPublicShop from '@/modules/public-shop/hooks/useGetPublicShop'
import { useGetPublicShopProducts } from '@/modules/public-shop/hooks/useGetPublicShopProducts'
import { useSetShopPrimaryColor } from '@/modules/public-shop/hooks/useSetShopPrimaryColor'
import SupplierBanner from '@/modules/public-shop/supplier-public-products/SupplierBanner'
import SupplierHeader from '@/modules/public-shop/supplier-public-products/SupplierHeader'
import SupplierInfoModal from '@/modules/public-shop/supplier-public-products/SupplierInfoModal'
import PublicProductListView from '@/modules/public-shop/supplier-public-products/list-view/PublicProductListView'
import PublicProductTileView from '@/modules/public-shop/supplier-public-products/tile-view/PublicProductTileView'
import { Spinner } from '@/modules/requisitions/components/spinner/Spinner'
import { ProductViewEnum } from '@/modules/requisitions/types/ProductViewEnum'
import Button from '@/modules/shared/components/button/Button'
import { Container } from '@/modules/shared/components/container/Container'
import EmptyState from '@/modules/shared/components/empty-state/EmptyState'
import { QueryResult } from '@/modules/shared/components/query-result/QueryResult'
import SearchInput from '@/modules/shared/components/search-input/SearchInput'
import { useMoney } from '@/modules/shared/hooks/useMoney'
import { useWindowSize } from '@/modules/shared/hooks/useWindowSize'
import { CartIcon } from '@/modules/shared/icons/CartIcon'
import { ChevronRightIcon } from '@/modules/shared/icons/ChevronRightIcon'
import { ControlBarIcon } from '@/modules/shared/icons/ControlBarIcon'
import { InfoIcon } from '@/modules/shared/icons/InfoIcon'
import SearchContainer from '@/modules/shared/search-container/SearchContainer'
import { classNames } from '@/modules/shared/utils/classNames'

export default function SupplierPublicProducts() {
  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const { isLargeScreen } = useWindowSize()
  const { loadingStates, publicProducts, onFetchMore, pageInfo } = useGetPublicShopProducts()
  const { publicShop, loading, error } = useGetPublicShop()
  const [productView, setProductView] = useState<ProductViewEnum>(ProductViewEnum.TILE_VIEW)
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false)
  const [showSupplierModal, setShowSupplierModal] = useState(false)
  const { requisition } = useGetLocalRequisition()

  useCreateLocalnRequisition()
  useSetShopPrimaryColor()

  return (
    <>
      <SupplierHeader />
      <Container className="py-4">
        <QueryResult loading={loading} error={error}>
          <SupplierBanner coverImage={publicShop?.shopBannerUrl} overlayLogo={publicShop?.shopOverlayLogoUrl} />
          <div className="flex w-full items-center justify-between rounded-md bg-white p-3.5 shadow-sm">
            <div className="flex gap-x-3">
              <img
                src={publicShop?.shopSquareLogoUrl || Logo}
                className="size-14"
                alt="SquareLogo"
                data-testid="squareLogo"
              />
              <div className="flex flex-col justify-center">
                <p className="text-sm font-bold">{publicShop?.name}</p>
                <p className="text-xs text-gray-500">{publicShop?.shortDescription}</p>
              </div>
            </div>
            <Button
              data-testid="supplier-info-button"
              className="flex size-11 items-center justify-center rounded-full bg-gray-200"
              onClick={() => setShowSupplierModal(true)}
            >
              <InfoIcon className="size-8 text-gray-600" />
            </Button>
          </div>
          <section className="flex w-full flex-col justify-between rounded-md bg-white shadow sm:flex-row">
            <div className="flex items-center gap-4 p-4">
              <div className="flex size-14 items-center justify-center rounded-md bg-gray-200">
                <CartIcon className="size-10" />
              </div>
              <div>
                <p className="font-bold" data-testid="page-header-title">
                  {formatMoney({ amount: requisition.totalValue })}
                </p>
                <p className="text-sm text-gray-500" data-testid="page-header-subtitle">
                  {t('requisitionInformation.cart.plusTaxOf', 'Plus Tax of {{ totalTaxValue }}', {
                    totalTaxValue: formatMoney({ amount: requisition.totalTaxValue }),
                  })}
                </p>
              </div>
            </div>
            <hr className="sm:hidden" />
            <div className="flex items-center p-4">
              <Link
                data-testid="view-cart-button"
                to={`summary${location.search}`}
                className="flex h-11 w-full items-center justify-between gap-1 rounded-md bg-primary px-4 text-sm text-white hover:brightness-95"
              >
                <span>{t('general.viewCart', 'View Cart')}</span>
                <ChevronRightIcon className="size-6" />
              </Link>
            </div>
          </section>
          <div className="hidden items-center justify-between lg:flex">
            <div className="flex items-center">
              <ControlBarIcon className="size-10" />
              <p className="ml-3 text-sm">{t('general.browserProducts', 'Browser Products')}</p>
            </div>
            <div className="flex gap-x-5">
              <div data-testid="product-view-tabs" className="flex gap-1.5 rounded-lg bg-gray-200 px-1.5 py-1">
                <Button
                  className={classNames('h-11 rounded-md border px-5 text-sm', {
                    'pointer-events-none border-gray-300 bg-white': productView === ProductViewEnum.TILE_VIEW,
                    'text-gray-500 hover:bg-gray-300': productView === ProductViewEnum.LIST_VIEW,
                  })}
                  onClick={() => setProductView(ProductViewEnum.TILE_VIEW)}
                >
                  {t('general.tileView', 'Tile View')}
                </Button>
                <Button
                  className={classNames('h-11 rounded-md border px-5 text-sm', {
                    'pointer-events-none border-gray-300 bg-white': productView === ProductViewEnum.LIST_VIEW,
                    'text-gray-500 hover:bg-gray-300': productView === ProductViewEnum.TILE_VIEW,
                  })}
                  onClick={() => setProductView(ProductViewEnum.LIST_VIEW)}
                >
                  {t('general.listView', 'List View')}
                </Button>
              </div>
            </div>
          </div>
          <SearchContainer
            showAdvancedSearch={showAdvancedSearch}
            setShowAdvancedSearch={setShowAdvancedSearch}
            className="2xl:hidden"
          >
            <SearchInput
              testId="product-search-input"
              placeholder={t('general.searchByProductNameOrBrand', 'Search by Product Name or Brand')}
              ariaLabel={t('general.searchByProductNameOrBrand', 'Search by Product Name or Brand')}
            />
          </SearchContainer>
          {showAdvancedSearch && (
            <div className="2xl:hidden">
              <AdvancedSearch />
            </div>
          )}
          <div className="flex gap-x-5">
            <div className="hidden h-fit w-full max-w-xs 2xl:block">
              <AdvancedSearch />
            </div>
            <div className="flex-1">
              {loadingStates.setVariablesLoading && <Spinner className="mt-5 h-14 md:w-16" />}
              {!loadingStates.setVariablesLoading && (
                <>
                  <Transition
                    show={productView === ProductViewEnum.TILE_VIEW || !isLargeScreen}
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div>
                      <InfiniteScroll
                        dataLength={publicProducts.length}
                        next={onFetchMore}
                        hasMore={!!pageInfo?.hasNextPage}
                        loader={<Spinner className="mt-5 h-14 md:w-16" />}
                      >
                        <PublicProductTileView publicProducts={publicProducts} />
                      </InfiniteScroll>
                    </div>
                  </Transition>
                  <Transition
                    show={productView === ProductViewEnum.LIST_VIEW && isLargeScreen}
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div>
                      <InfiniteScroll
                        dataLength={publicProducts.length}
                        next={onFetchMore}
                        hasMore={!!pageInfo?.hasNextPage}
                        loader={<Spinner className="mt-5 h-14 md:w-16" />}
                      >
                        <PublicProductListView publicProducts={publicProducts} />
                      </InfiniteScroll>
                    </div>
                  </Transition>
                </>
              )}
              {!loadingStates.setVariablesLoading && publicProducts.length === 0 && (
                <EmptyState
                  message={t('shopPage.productList.noProductFound', "We couldn't find any products to display.")}
                />
              )}
            </div>
          </div>
        </QueryResult>
      </Container>
      <SupplierInfoModal
        showModal={showSupplierModal}
        setShowModal={setShowSupplierModal}
        supplier={publicShop || null}
      />
    </>
  )
}
