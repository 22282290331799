import { Dispatch, SetStateAction, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useSession } from '@/modules/access/hooks/useSession'
import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { Button } from '@/modules/shared/components/button/Button'
import { Modal } from '@/modules/shared/components/modal/ModalComponent'
import useChangeOrganisation from '@/modules/shared/hooks/useChangeOrganisation'
import SelectOrganisation from '@/modules/shared/layouts/header/switch-organisation-modal/SelectOrganisation'
import { generateOrgName } from '@/modules/shared/utils/generateOrgName'

interface SwitchOrganisationModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
}

export default function SwitchOrganisationModal({ showModal, setShowModal }: SwitchOrganisationModalProps) {
  const { t } = useTranslation()

  const [selectedOrganisation, setSelectedOrganisation] = useState<number | null>(null)
  const { currentOrganisation } = useSessionUser()
  const [changeOrganisation, { loading }] = useChangeOrganisation()
  const { currentUser } = useSession()

  const onConfirm = () => {
    changeOrganisation({
      variables: {
        input: {
          id: Number(selectedOrganisation),
        },
      },
      onCompleted() {
        window.location.assign('/')
      },
    })
  }

  const onCloseModal = () => {
    setSelectedOrganisation(null)
    setShowModal(false)
  }

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel
        data-testid="switch-organisation"
        className="flex h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white text-sm shadow-xl transition-all md:max-w-[56.25rem]"
      >
        <Modal.Title
          title={t('general.switchOrganisation', 'Switch Organisation')}
          onCloseModal={onCloseModal}
          testId="switch-organisation-title"
        />

        <Modal.Body id="InfiniteScroll">
          <p className="font-bold">{t('general.currentOrganisation', 'Current Organisation')}</p>
          <p className="text-gray-500">
            {t(
              'access.switchOrganisation.currentOrganisationDescription',
              "You're currently using PurchasePlus at the following organisation."
            )}
          </p>
          <p
            data-testid="current-organisation-name"
            className="my-2 w-full rounded-md border border-gray-200 bg-gray-100 p-3"
          >
            {generateOrgName({
              name: currentOrganisation?.name || null,
              parent: currentOrganisation?.parent,
            })}
          </p>
          <p className="mt-6 font-bold">{t('general.organisationList', 'Organisation List')}</p>
          <p data-testid="switch-organisation-description" className="text-gray-500">
            {currentUser?.isAdmin ? (
              <Trans t={t} i18nKey="access.switchOrganisation.organisationListAdminDescription">
                Please select an Organisation that you wish to switch to. As a P+ Administrator, you have the permission
                to switch to any organisation in PurchasePlus. When switching organisation, your entire session will
                change to that organisation. You can switch between organisations at any time. Please scroll the list
                below or search for an organisation by name or by organisation id.
              </Trans>
            ) : (
              <Trans t={t} i18nKey="access.switchOrganisation.organisationListDescription">
                Please select an Organisation that you wish to switch to. An organisation is a business, department or
                property that you are allowed to use PurchasePlus on behalf of. When switching to a different
                organisation, your entire session will change to that organisation. You can switch between organisations
                at any time. Please scroll the list below or search for an organisation that you're able to switch to.
              </Trans>
            )}
          </p>
          <SelectOrganisation
            showModal={showModal}
            onSelectOrganisation={setSelectedOrganisation}
            selectedOrganisation={selectedOrganisation}
          />
        </Modal.Body>
        <Modal.Footer className="flex flex-col-reverse items-center justify-end gap-2 md:flex-row">
          <Button
            className="h-11 w-full rounded-md bg-gray-200 px-5 py-3 text-sm md:w-fit"
            data-testid="switch-organisation-cancel"
            onClick={onCloseModal}
          >
            {t('general.cancel', 'Cancel')}
          </Button>
          <Button
            disabled={!selectedOrganisation}
            className="h-11 w-full rounded-md bg-primary px-5 py-3 text-sm text-white md:w-fit"
            onClick={onConfirm}
            loading={loading}
            data-testid="switch-organisation-confirm"
          >
            {t('general.switchOrganisation', 'Switch Organisation')}
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
