import { Disclosure } from '@headlessui/react'
import { useTranslation } from 'react-i18next'

import SearchInput from '@/modules/shared/components/search-input/SearchInput'
import { useWindowSize } from '@/modules/shared/hooks/useWindowSize'
import { ChevronDownIcon } from '@/modules/shared/icons/ChevronDownIcon'
import { QueryParameter } from '@/modules/shared/types/QueryParameter'
import { classNames } from '@/modules/shared/utils/classNames'

export default function SearchByProductCode() {
  const { t } = useTranslation()
  const { twoXL, lg } = useWindowSize()

  return (
    <section className="px-4 pb-4 pt-3">
      <Disclosure defaultOpen>
        {({ open }) => (
          <>
            <Disclosure.Button
              className="flex w-full items-center justify-between py-2"
              data-testid="search-by-code-section"
            >
              <span className="text-sm">{t('general.searchByCode', 'Search By Code')}</span>
              <ChevronDownIcon
                className={classNames('size-5 transition-all duration-300', {
                  'rotate-180 transform': open,
                })}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="mt-2 flex flex-col gap-2 md:flex-row">
              <SearchInput
                testId="product-code-search-input"
                placeholder={t('general.pPlusProductCode', 'P+ Product Code')}
                ariaLabel={t('general.pPlusProductCode', 'P+ Product Code')}
                searchTermURLParamType={QueryParameter.PPlusCode}
                className={classNames('w-full', { 'max-w-[15.625rem]': !twoXL && lg })}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </section>
  )
}
