import { Controller } from 'react-hook-form'
import { useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { Product } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { ComboboxClient } from '@/modules/shared/components/combobox/client/ComboboxClient'
import { measureNameValues } from '@/modules/shared/components/measure-name-dropdown/MeasureNameValues'
import NumberInput from '@/modules/shared/components/number-input/NumberInput'
import { packNameValues } from '@/modules/shared/components/pack-name-dropdown/PackNameValues'
import { Tooltip } from '@/modules/shared/components/tooltip/Tooltip'
import { HELP_LINK_PRODUCT_CREATION, HELP_LINK_PRODUCT_CREATION_AS_SUPPLIER } from '@/modules/shared/help'
import { QuestionMarkIcon } from '@/modules/shared/icons/QuestionMarkIcon'

interface ProductUnitSizeProps {
  product?: Product
  disabled?: boolean
  description?: string
  hidePackName?: boolean
}

export default function ProductUnitSize({ product, disabled, description, hidePackName }: ProductUnitSizeProps) {
  const { t } = useTranslation()
  const { currentOrganisation } = useSessionUser()
  const formMethods = useFormContext()

  return (
    <div>
      <p className="font-bold">{t('general.unitSize', 'Unit Size')}</p>
      <p className="text-gray-500">
        {description || (
          <Trans t={t} i18nKey="updateProductModal.unitSize.description">
            The size of 1 unit of this item e.g. a 375ml Can, or a 1kg Bag. Good product definitions are very important
            so please read our detailed{' '}
            <a
              data-testid="view-support-link"
              href={
                currentOrganisation?.isAPurchaser ? HELP_LINK_PRODUCT_CREATION : HELP_LINK_PRODUCT_CREATION_AS_SUPPLIER
              }
              target="_blank"
              rel="noreferrer"
              className="text-primary"
            >
              product creation guide
            </a>{' '}
            to see examples of common product definitions.
          </Trans>
        )}
      </p>
      <div className="mt-3 flex w-1/2 flex-col gap-y-3">
        <div className="flex flex-col gap-y-1">
          <p className="flex items-center font-bold">
            {t('general.unitSize', 'Unit Size')}
            <Tooltip
              content={t(
                'utilityProducts.unitSizeTooltip',
                'The size of 1 unit of this product e.g. "375" for a 375ml Can.'
              )}
            >
              <QuestionMarkIcon className="ml-1 size-4" />
            </Tooltip>
          </p>
          <Controller
            control={formMethods.control}
            name="itemSize"
            render={({ field }) => (
              <NumberInput
                className="h-[2.875rem] w-full rounded-md text-sm"
                defaultValue={product?.itemSize || undefined}
                onChange={(e) => field.onChange(e || null)}
                aria-label="item-size"
                data-testid="unit-size-input"
                placeholder={t('general.unitSize', 'Unit Size')}
                isDisabled={disabled}
                hasError={!!formMethods.formState.errors.itemSize}
                errorMessage={t('product.unitSizeError', 'Unit size is required.')}
              />
            )}
          />
        </div>
        <div className="flex flex-col gap-y-1">
          <p className="flex items-center font-bold">
            {t('general.unitOfMeasure', 'Unit of Measure')}
            <Tooltip
              content={t(
                'utilityProducts.unitMeasureTooltip',
                'The unit of measure used for the unit size e.g. "ml" for a 375ml Can.'
              )}
            >
              <QuestionMarkIcon className="ml-1 size-4" />
            </Tooltip>
          </p>
          <Controller
            control={formMethods.control}
            name="itemMeasure"
            render={({ field }) => (
              <ComboboxClient
                items={[{ value: '--' }, ...measureNameValues]}
                keyExtractor={(e) => e?.value || ''}
                defaultValue={product?.itemMeasure ? { value: product?.itemMeasure } : { value: '--' }}
                keyFilter="value"
                onSelected={(e) => field.onChange(e.value === '--' ? null : e.value)}
                testId="measureName"
                disabled={disabled}
                hasError={!!formMethods.formState.errors.itemMeasure}
                errorMessage={t('product.unitMeasureError', 'Unit measure is required.')}
              />
            )}
          />
        </div>
        {!hidePackName && (
          <div className="flex flex-col gap-y-1">
            <p className="flex items-center font-bold">
              {t('general.itemPackName', 'Item Pack Name')}
              <Tooltip
                content={t(
                  'utilityProducts.unitNameTooltip',
                  'If applicable, the name given to the pack that the unit is contained in e.g "Can" for a 375ml Can.'
                )}
              >
                <QuestionMarkIcon className="ml-1 size-4" />
              </Tooltip>
            </p>
            <Controller
              control={formMethods.control}
              name="itemPackName"
              render={({ field }) => (
                <ComboboxClient
                  items={packNameValues}
                  keyExtractor={(e) => e?.key || ''}
                  defaultValue={
                    product?.itemPackName
                      ? { key: product?.itemPackName, value: product?.itemPackName }
                      : { key: 'null', value: '--' }
                  }
                  keyFilter="value"
                  onSelected={(e) => field.onChange(e.key === 'null' ? null : e.key)}
                  testId="packName"
                  disabled={disabled}
                  hasError={!!formMethods.formState.errors.itemPackName}
                  errorMessage={t('product.unitPackNameError', 'Unit pack name is required.')}
                />
              )}
            />
          </div>
        )}
      </div>
    </div>
  )
}
