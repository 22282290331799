import { zodResolver } from '@hookform/resolvers/zod'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import NoImage from '@/assets/images/no-image.svg'
import {
  AddOrderInstructionSchema,
  AddOrderInstructionSchemaInput,
} from '@/modules/public-shop/cart/summary/table-view/AddOrderInstructionSchema'
import { useUpdateLocalRequisitionRequisition } from '@/modules/public-shop/hooks/useUpdateLocalRequisition'
import { LocalRequisitionLine } from '@/modules/public-shop/types/requisitionLine'
import { Button } from '@/modules/shared/components/button/Button'
import { Modal } from '@/modules/shared/components/modal/ModalComponent'

interface AddOrderInstructionModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  line: LocalRequisitionLine
}

export default function AddOrderInstructionModal(props: AddOrderInstructionModalProps) {
  const { line, showModal, setShowModal } = props

  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<AddOrderInstructionSchemaInput>({ resolver: zodResolver(AddOrderInstructionSchema) })
  const { onUpdateRequisitionLine } = useUpdateLocalRequisitionRequisition()

  useEffect(() => {
    reset({ orderInstruction: line.orderInstruction || '' })
  }, [line.orderInstruction])

  const onCloseModal = () => {
    reset()
    setShowModal(false)
  }

  const onSubmit: SubmitHandler<AddOrderInstructionSchemaInput> = (data) => {
    const { orderInstruction } = data
    onUpdateRequisitionLine({ lineId: line.id, orderInstruction })
    onCloseModal()
  }

  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[37.5rem]"
      >
        <Modal.Title title={t('general.addOrderInstruction', 'Add Order Instruction')} onCloseModal={onCloseModal} />

        <Modal.Body>
          <section className="flex items-center gap-x-4 rounded-md border p-4">
            <img className="size-28 border" src={line.product.image || NoImage} alt="product" />
            <span>{line.product.longDescription}</span>
          </section>
          <section className="mt-4">
            <h2 className="text-sm font-semibold">{t('general.orderInstruction', 'Order Instruction')}</h2>
            <p className="text-sm">
              {t(
                'shopPage.cart.summary.table.orderInstructionExplain',
                'Add order instructions here for this product. These instructions will be added to the Purchase Order and will appear to the Supplier for this product line.'
              )}
            </p>
            <textarea
              {...register('orderInstruction')}
              data-testid="order-instruction-input"
              className="mt-4 w-full rounded-md border border-gray-300 p-3 text-sm shadow-sm focus:outline-none focus:ring-primary"
              rows={6}
              placeholder={t('general.orderInstruction', 'Order Instruction')}
            ></textarea>
          </section>
        </Modal.Body>
        <Modal.Footer className="flex flex-col-reverse items-center justify-end gap-2 md:flex-row">
          <Button
            type="button"
            className="h-11 w-full rounded-md bg-gray-200 px-5 text-sm md:w-fit"
            onClick={onCloseModal}
          >
            {t('general.cancel', 'Cancel')}
          </Button>
          {isDirty && (
            <Button
              data-testid="save-changes-order-instruction-btn"
              type="submit"
              className="h-11 w-full rounded-md bg-primary px-5 text-sm text-white md:w-fit"
            >
              {t('general.saveChanges', 'Save Changes')}
            </Button>
          )}
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
