import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/modules/shared/components/button/Button'
import { Modal } from '@/modules/shared/components/modal/ModalComponent'
import useAbout from '@/modules/shared/hooks/useAbout'
import { useCopyToClipboard } from '@/modules/shared/hooks/useCopyToClipboard'
import AboutItem from '@/modules/shared/layouts/header/about/AboutItem'

interface AboutModalProps {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
}

export default function AboutModal({ showModal, setShowModal }: AboutModalProps) {
  const { t } = useTranslation()
  const { onCopy, hasCopied } = useCopyToClipboard()
  const { session, organisationDetails, userPreferences, userPermissions, organisationPreferences } = useAbout()

  const onCloseModal = () => setShowModal(false)

  const copyContent = () => ({
    Session: Object.fromEntries(session.map(({ title, value }) => [title, value])),
    OrganisationDetails: Object.fromEntries(organisationDetails.map(({ title, value }) => [title, value])),
    UserPermissions: Object.fromEntries(userPermissions.map(({ title, value }) => [title, value])),
    UserPreferences: Object.fromEntries(userPreferences.map(({ title, value }) => [title, value])),
    OrganisationPreferences: Object.fromEntries(organisationPreferences.map(({ title, value }) => [title, value])),
  })
  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel className="flex h-[60vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[56.25rem]">
        <Modal.Title title={t('general.about', 'About')} onCloseModal={onCloseModal} />
        <Modal.Body>
          <div className="space-y-3">
            <AboutItem title={t('general.sessionDetails', 'Session Details')} items={session} />
            <AboutItem title={t('general.organisationDetails', 'Organisation Details')} items={organisationDetails} />
            <AboutItem title={t('general.userPreferences', 'User Preferences')} items={userPreferences} />
            <AboutItem title={t('general.userPermissions', 'User Permissions')} items={userPermissions} />
            <AboutItem
              title={t('general.organisationPreferences', 'Organisation Preferences')}
              items={organisationPreferences}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="flex flex-col items-center justify-center gap-2 md:flex-row md:justify-between">
          <Button
            className="h-11 w-full rounded-md bg-gray-200 px-5 py-3 text-sm md:w-fit"
            onClick={() => onCopy(JSON.stringify(copyContent()))}
          >
            {hasCopied ? t('general.copied', 'Copied') : t('general.copyToClipboard', 'Copy to Clipboard')}
          </Button>
          <Button className="h-11 w-full rounded-md bg-gray-200 px-5 py-3 text-sm md:w-fit" onClick={onCloseModal}>
            {t('general.cancel', 'Cancel')}
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
