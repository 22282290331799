import { useTranslation } from 'react-i18next'
import { Link, generatePath } from 'react-router-dom'

import { Product } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import { Product as LegacyProduct } from '@/graphql/purchasing/generated/purchasing_graphql'
import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { Input as FormatProductInput } from '@/modules/requisitions/utils/formatProductItem'
import Button from '@/modules/shared/components/button/Button'
import { Modal } from '@/modules/shared/components/modal/ModalComponent'
import BarcodeDetails from '@/modules/shared/components/product-modal/BarcodeDetails'
import DeliveryFeesDetails from '@/modules/shared/components/product-modal/DeliveryFees'
import ProductDetails from '@/modules/shared/components/product-modal/ProductDetails'
import SubstituteProducts from '@/modules/shared/components/product-modal/SubstituteProducts'
import SupplierDetails from '@/modules/shared/components/product-modal/SupplierDetails'
import { Supplier } from '@/modules/shared/components/product-modal/types/Supplier'
import { MaybeProps } from '@/modules/shared/types/MaybeProps'
import { classNames } from '@/modules/shared/utils/classNames'
import { formatProductName } from '@/modules/shared/utils/formatProductName'

// Properties that will only be used in this modal
interface LineProps {
  taxPercentage: number
  unitPrice: number | string
  image: string
  supplierProductCode: string
  purchaserProductCode: string
  longDescription: string
  product: LegacyProduct | Product
}

export type Line = Partial<MaybeProps<LineProps>>

interface ProductModalProps<T extends Line> {
  showModal: boolean
  setShowModal: (value: boolean) => void
  line?: T
  supplier?: Supplier
  product?: Product | LegacyProduct
  formatProduct?: (input: FormatProductInput) => string
  isSearchProductUtility?: boolean
}

export default function ProductModal<T extends Line>({
  showModal,
  setShowModal,
  supplier,
  line,
  product,
  formatProduct,
  isSearchProductUtility = false,
}: ProductModalProps<T>) {
  const { brand, itemDescription, id, owner } = line?.product || product || {}
  const { t } = useTranslation()
  const { currentOrganisation, user, currentLegalEntity } = useSessionUser()
  const onCloseModal = () => setShowModal(false)
  return (
    <Modal showModal={showModal} onCloseModal={onCloseModal}>
      <Modal.Panel
        data-testid="product-details-modal"
        className="flex h-[80vh] w-full flex-col overflow-hidden rounded-md bg-white shadow-xl transition-all md:max-w-[56.25rem]"
      >
        <Modal.Title title={formatProductName(brand || null, itemDescription || null)} onCloseModal={onCloseModal} />

        <Modal.Body className="overflow-y-scroll">
          <ProductDetails line={line} supplier={supplier} product={product} formatProduct={formatProduct} />
          {supplier && (
            <>
              <SupplierDetails supplier={supplier} />
              <DeliveryFeesDetails supplier={supplier} />
            </>
          )}
          <BarcodeDetails productId={Number(id)} isSearchProductUtility={isSearchProductUtility} />
          {!currentOrganisation?.isASupplier && (
            <SubstituteProducts productId={Number(id)} isSearchProductUtility={isSearchProductUtility} />
          )}
        </Modal.Body>
        <Modal.Footer
          className={classNames('flex flex-col gap-2 md:flex-row md:items-center', {
            'justify-between': !!id,
            'justify-end': !id || !owner,
          })}
        >
          {(user?.isAdmin ||
            owner?.id === currentLegalEntity?.id ||
            owner?.id === currentOrganisation?.id ||
            owner?.id === 1) && (
            <Link
              to={generatePath(`${isSearchProductUtility ? '/utilities' : ''}/products/:productId/details`, {
                productId: String(id),
              })}
              target="_blank"
              className="flex h-11 w-full items-center justify-center rounded-md bg-gray-200 px-5 text-center text-sm md:w-fit"
            >
              {t('general.manageProduct', 'Manage Product')}
            </Link>
          )}
          <Button className="h-11 w-full rounded-md bg-gray-200 px-5 text-sm md:w-fit" onClick={onCloseModal}>
            {t('general.close', 'Close')}
          </Button>
        </Modal.Footer>
      </Modal.Panel>
    </Modal>
  )
}
