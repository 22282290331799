import { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import AppLayout from '@/modules/shared/layouts/app-layout/AppLayout'
import NotFound from '@/modules/shared/pages/not-found/NotFound'

// Inside app layout
const RequisitionRoutes = lazy(() => import('@/routes/shared-routes/RequisitionRoutes'))
const PurchaseOrderRoutes = lazy(() => import('@/routes/shared-routes/PurchaseOrderRoutes'))
const BuyListRoutes = lazy(() => import('@/routes/shared-routes/BuyListRoutes'))
const CatalogRoutes = lazy(() => import('@/routes/shared-routes/CatalogRoutes'))
const StockLocationRoutes = lazy(() => import('@/routes/shared-routes/StockLocationRoutes'))
const InventoryRoutes = lazy(() => import('@/routes/shared-routes/InventoryRoutes'))
const RecipeRoutes = lazy(() => import('@/routes/shared-routes/RecipeRoutes'))
const MySupplierRoutes = lazy(() => import('@/routes/shared-routes/MySupplierRoutes'))
const SupplierDirectoryRoutes = lazy(() => import('@/routes/shared-routes/SupplierRoutes'))
const InvoiceRoutes = lazy(() => import('@/routes/shared-routes/InvoiceRoutes'))
const CreditNoteRoutes = lazy(() => import('@/routes/shared-routes/CreditNoteRoutes'))
const PurchaserProductRoutes = lazy(() => import('@/routes/shared-routes/PurchaserProductRoutes'))
const ReportRoutes = lazy(() => import('@/routes/shared-routes/ReportRoutes'))
const UserRoutes = lazy(() => import('@/routes/shared-routes/UserRoutes'))
const PurchaserSystemSettingsRoutes = lazy(() => import('@/routes/shared-routes/PurchaserSystemSettingsRoutes'))
const BarcodeRoutes = lazy(() => import('@/routes/shared-routes/BarcodeRoutes'))
const MyAccountRoutes = lazy(() => import('@/routes/shared-routes/MyAccountRoutes'))
const IngredientRoutes = lazy(() => import('@/routes/shared-routes/IngredientRoutes'))
const MenuRoutes = lazy(() => import('@/routes/shared-routes/MenuRoutes'))
const UtilityRoutes = lazy(() => import('@/routes/shared-routes/UtilityRoutes'))
const ShopRoutes = lazy(() => import('@/routes/public-routes/ShopRoutes'))
const PointOfSaleRoutes = lazy(() => import('@/routes/shared-routes/PointOfSaleRoutes'))
const PurchasingDashboard = lazy(() => import('@/modules/purchasing/pages/dashboard/PurchasingDashboard'))

export default function PurchaserRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard" />} />

      {/* Inside the app layout */}
      <Route element={<AppLayout />}>
        <Route path="/dashboard" element={<PurchasingDashboard />} />
        <Route path="/requisitions/*" element={<RequisitionRoutes />} />
        <Route path="/purchase-orders/*" element={<PurchaseOrderRoutes />} />
        <Route path="/invoices/*" element={<InvoiceRoutes />} />
        <Route path="/credit-notes/*" element={<CreditNoteRoutes />} />
        <Route path="/buy-lists/*" element={<BuyListRoutes />} />
        <Route path="/catalogs/*" element={<CatalogRoutes />} />
        <Route path="/products/*" element={<PurchaserProductRoutes />} />
        <Route path="/recipes/*" element={<RecipeRoutes />} />
        <Route path="/my-suppliers/*" element={<MySupplierRoutes />} />
        <Route path="/supplier-directory/*" element={<SupplierDirectoryRoutes />} />
        <Route path="/inventory/*" element={<InventoryRoutes />} />
        <Route path="/stocktakes/*" element={<StockLocationRoutes />} />
        <Route path="/reports/*" element={<ReportRoutes />} />
        <Route path="/users/*" element={<UserRoutes />} />
        <Route path="/settings/*" element={<PurchaserSystemSettingsRoutes />} />
        <Route path="/barcodes/*" element={<BarcodeRoutes />} />
        <Route path="/menus/*" element={<MenuRoutes />} />
        <Route path="/ingredients/*" element={<IngredientRoutes />} />
        <Route path="/my-account/*" element={<MyAccountRoutes />} />

        <Route path="/point-of-sale/*" element={<PointOfSaleRoutes />} />
        <Route path="/utilities/*" element={<UtilityRoutes />} />
        <Route path="/shop/*" element={<ShopRoutes />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
