import { useQuery } from '@apollo/client'

import { GetPublicShopDocument } from '@/graphql/purchase-plus-public/generated/getPublicShop.generated'
import { PURCHASE_PLUS_PUBLIC_GRAPHQL_API } from '@/modules/shared/constants'
import useShopId from '@/modules/shared/hooks/params/useShopId'

export default function useGetPublicShop() {
  const shopId = useShopId()
  const { data, loading, error } = useQuery(GetPublicShopDocument, {
    variables: {
      publicShopId: Number(shopId),
    },
    context: {
      uri: PURCHASE_PLUS_PUBLIC_GRAPHQL_API,
    },
  })

  return {
    publicShop: data?.publicShop,
    loading,
    error,
  }
}
