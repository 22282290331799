import { NetworkStatus } from '@apollo/client'
import { autoUpdate, size, useFloating } from '@floating-ui/react-dom'
import { Combobox, ComboboxInput, ComboboxOptions, Portal } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'

import { Chip } from '@/modules/shared/components/chip/Chip'
import ComboboxOptionButton from '@/modules/shared/components/combobox/shared/ComboboxOptionButton'
import { ComboboxOptionItem } from '@/modules/shared/components/combobox/shared/ComboboxOptionItem'
import { ComboBoxBaseProps } from '@/modules/shared/components/combobox/types'
import { useDebounce } from '@/modules/shared/hooks/useDebounce'
import { Loading } from '@/modules/shared/icons-special/Loading'
import { checkNetworkStatus } from '@/modules/shared/utils/checkNetworkStatus'
import { classNames } from '@/modules/shared/utils/classNames'

interface ComboboxServerProps<DataType> extends ComboBoxBaseProps<DataType> {
  onDisplay: (e: DataType) => string
  onInputChange: (value: string) => void
  onFetchMore?: () => void
  hasMore?: boolean
  networkStatus: NetworkStatus
  resetSelectItem?: number | null
  comboItemSelected?: boolean
  disableSelected?: boolean
  onChipRemove?: () => void
}

function ComboboxServer<T>({
  onInputChange,
  keyExtractor,
  onSelected,
  onFetchMore,
  networkStatus,
  disabled,
  className,
  placeholder,
  items = [],
  onDisplay,
  hasMore,
  hasError,
  errorMessage,
  testId,
  defaultValue,
  resetSelectItem,
  comboItemSelected,
  disableSelected,
  onChipRemove,
  floating,
  loading: actionLoading,
}: ComboboxServerProps<T>) {
  const { t } = useTranslation()
  const { ref, inView } = useInView({ threshold: 1 })
  const [query, setQuery] = useState('')
  const [inputFocus, setInputFocus] = useState(false)
  const [selectedItem, setSelectedItem] = useState<T | null>(null)
  const debouncedQuery = useDebounce(query, 500)
  const {
    x,
    y,
    strategy,
    refs: { setReference, setFloating },
  } = useFloating({
    placement: 'bottom',
    whileElementsMounted: autoUpdate,
    middleware: [
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          })
        },
      }),
    ],
  })

  const { setVariablesLoading, loading, fetchMoreLoading } = checkNetworkStatus(networkStatus)

  useEffect(() => {
    if (inputFocus) {
      onInputChange(debouncedQuery)
    }
  }, [debouncedQuery])

  useEffect(() => {
    if (selectedItem) {
      onSelected(selectedItem)
    }
  }, [selectedItem])

  useEffect(() => {
    if (inView && hasMore) {
      onFetchMore && onFetchMore()
    }
  }, [inView, hasMore])

  useEffect(() => {
    if (defaultValue) {
      setSelectedItem(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    if (resetSelectItem) {
      setSelectedItem(null)
    }
  }, [resetSelectItem])

  const getSelectedItem = (item: T) => {
    return item && onDisplay(item)
  }

  const renderItems = () => {
    if (items.length > 0) {
      return items.map((item, index) => (
        <ComboboxOptionItem
          key={`combobox-option-item-${index}`}
          item={item}
          testId={testId}
          keyExtractor={keyExtractor}
          getSelectedItem={getSelectedItem}
          selectedItem={selectedItem}
        />
      ))
    }
    return (
      <div
        data-testid={`nothing-found${testId ? `-${testId}` : ''}`}
        className="cursor-default select-none px-4 py-2 text-gray-700"
      >
        <h1>{t('general.noResultsAvailable.', 'No results available')}</h1>
      </div>
    )
  }

  // Headless UI combobox isn't rendered in a portal so the combobox dropdown will be cut off by the table component (or any other components)
  // So we have to render the dropdown in a portal and use useFloating hook to position it correctly.
  // Note: Turn on `floating` if you want to render the combobox dropdown in a portal.
  // Reference: https://github.com/tailwindlabs/headlessui/discussions/1925
  const FloatingPortal = floating ? Portal : Fragment
  const floatingStyles = floating
    ? {
        position: strategy,
        top: y ?? 0,
        left: x ?? 0,
      }
    : undefined

  return (
    <Combobox
      as="div"
      className={classNames('w-full', className)}
      disabled={disabled || loading || actionLoading}
      value={disableSelected ? null : selectedItem}
      data-testid={testId}
      onChange={setSelectedItem}
      immediate
    >
      <div className={classNames('relative', 'mt-1', { 'pb-1': hasError })}>
        <ComboboxInput
          ref={floating ? setReference : null}
          autoComplete="off"
          aria-invalid={hasError ? 'true' : 'false'}
          data-testid={`combobox-input${testId ? `-${testId}` : ''}`}
          className={classNames(
            'w-full rounded-md border py-3 pl-3 pr-10 shadow-sm transition focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm',
            {
              'bg-gray-200': disabled || loading || actionLoading,
              'cursor-not-allowed': disabled,
              'border-error': hasError,
              'border-gray-300': !hasError,
              'bg-white': !(disabled || loading || actionLoading || comboItemSelected),
              'text-gray-500': loading || actionLoading,
              'border-primary bg-primary/10 text-primary': comboItemSelected,
            }
          )}
          placeholder={placeholder}
          onFocus={() => setInputFocus(true)}
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(item: T) => getSelectedItem(item)}
        />

        <ComboboxOptionButton testId={testId} disabled={disabled} loading={loading || actionLoading} />

        <FloatingPortal>
          <ComboboxOptions
            ref={floating ? setFloating : null}
            style={floatingStyles}
            data-testid={`options-wrapper${testId ? `-${testId}` : ''}`}
            className={classNames(
              'absolute z-10 mt-1 max-h-72 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-primary/5 focus:outline-none sm:text-sm',
              { 'w-full': !floating }
            )}
          >
            {setVariablesLoading ? (
              <div className="cursor-default select-none px-4 py-2 text-center text-gray-700">
                <Loading className="size-5 fill-white text-gray-300" />
              </div>
            ) : (
              renderItems()
            )}

            {fetchMoreLoading && hasMore && (
              <div className="py-2 text-center">
                <Loading className="size-5 fill-white text-gray-300" />
              </div>
            )}
            {!setVariablesLoading && <div ref={ref} />}
          </ComboboxOptions>
        </FloatingPortal>
      </div>
      {hasError && (
        <span className="text-sm text-error" role="alert">
          {errorMessage}
        </span>
      )}
      {onChipRemove && selectedItem && (
        <div className="mt-3">
          <Chip
            onRemoveChip={() => {
              setSelectedItem(null)
              setQuery('')
              onInputChange('')
              onChipRemove && onChipRemove()
            }}
            label={getSelectedItem(selectedItem)}
          />
        </div>
      )}
    </Combobox>
  )
}

export default ComboboxServer
