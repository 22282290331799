import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { InceptionTypeEnum } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { useCreateCreditNoteLine } from '@/modules/invoices/hooks/useCreateCreditNoteLine'
import { useCreateOrUpdatePartyProduct } from '@/modules/products/hooks/useCreateOrUpdatePartyProduct'
import { CreateProductFormInputs } from '@/modules/shared/components/create-product-modal/CreateProductFormSchema'
import { UseCreateAndAddProductProps } from '@/modules/shared/components/create-product-modal/hooks/useCreateAndAddProductRequisition'
import { displayToast } from '@/modules/shared/components/toast/displayToast'
import { useAlert } from '@/modules/shared/hooks/useAlert'
import { useCreateInformalProduct } from '@/modules/shared/hooks/useCreateInformalProduct'

export function useCreateAndAddProductCreditNote({ quickAdd, refetch, onCloseModal, id }: UseCreateAndAddProductProps) {
  const { currentOrganisation } = useSessionUser()
  const { t } = useTranslation()
  const [createInformalProduct, { loading: createInformalProductLoading }] = useCreateInformalProduct()
  const [createCreditNoteLine, { loading: createCreditNoteLineLoading }] = useCreateCreditNoteLine()

  const navigate = useNavigate()
  const { alertDialog } = useAlert()

  const [createOrUpdatePartlyProduct] = useCreateOrUpdatePartyProduct()

  const onCreateAndAddProductCreditNote = (input: CreateProductFormInputs) => {
    const {
      categoryId,
      brand,
      itemDescription,
      unitPrice,
      quantity,
      itemMeasure,
      itemSize,
      itemPackName,
      itemSellPackName,
      itemSellQuantity,
      taxPercentage,
      code,
      externalId,
    } = input
    createInformalProduct({
      variables: {
        input: {
          categoryId,
          brand,
          itemDescription,
          locale: 'en',
          itemMeasure,
          itemSize,
          itemPackName: itemPackName === 'null' ? null : itemPackName,
          itemSellPackName,
          itemSellQuantity,
          externalId,
          inceptionType: InceptionTypeEnum.Manual,
        },
      },
      onCompleted: async (data) => {
        const createdProductId = Number(data.createInformalProduct.informalProduct?.id)
        if (code) {
          await createOrUpdatePartlyProduct({
            variables: {
              input: {
                productId: createdProductId,
                organisationId: Number(currentOrganisation?.id),
                code: code,
              },
            },
          })
        }
        createCreditNoteLine({
          variables: {
            input: {
              productId: createdProductId,
              taxPercentage,
              creditNoteId: Number(id),
              unitPrice,
              quantity,
            },
          },
          onCompleted: () => {
            if (quickAdd) {
              onCloseModal && onCloseModal()
              displayToast({ title: t('general.productAdded', 'Product Added') })
              refetch && refetch()
            } else {
              navigate(generatePath('/credit-notes/:creditNoteId/summary', { creditNoteId: String(id) }))
            }
          },
        })
      },
      onError() {
        alertDialog({ type: 'error' })
      },
    })
  }

  return {
    onCreateAndAddProductCreditNote,
    loading: createInformalProductLoading || createCreditNoteLineLoading,
  }
}
