import { generatePath, useNavigate } from 'react-router-dom'

import { InceptionTypeEnum } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { useCreateOrUpdatePartyProduct } from '@/modules/products/hooks/useCreateOrUpdatePartyProduct'
import { useCreateLegacyReceivingDocumentLineItem } from '@/modules/purchase-orders/hooks/useCreateLegacyReceivingDocumentLineItem'
import { CreateProductFormInputs } from '@/modules/shared/components/create-product-modal/CreateProductFormSchema'
import { UseCreateAndAddProductProps } from '@/modules/shared/components/create-product-modal/hooks/useCreateAndAddProductRequisition'
import usePurchaseOrderId from '@/modules/shared/hooks/params/usePurchaseOrderId'
import { useAlert } from '@/modules/shared/hooks/useAlert'
import { useCreateInformalProduct } from '@/modules/shared/hooks/useCreateInformalProduct'

export function useCreateAndAddProductGRNote({ id }: UseCreateAndAddProductProps) {
  const { currentOrganisation } = useSessionUser()
  const purchaseOrderId = usePurchaseOrderId()
  const [createInformalProduct, { loading: createInformalProductLoading }] = useCreateInformalProduct()
  const [createReceivingDocumentLineItem, { loading: createReceivingDocumentLineLoading }] =
    useCreateLegacyReceivingDocumentLineItem()
  const navigate = useNavigate()
  const { alertDialog } = useAlert()
  const [createOrUpdatePartlyProduct] = useCreateOrUpdatePartyProduct()

  const onCreateAndAddProductGRNote = (input: CreateProductFormInputs) => {
    const {
      categoryId,
      brand,
      itemDescription,
      unitPrice,
      quantity,
      itemMeasure,
      itemSize,
      itemPackName,
      itemSellPackName,
      itemSellQuantity,
      code,
      externalId,
    } = input
    createInformalProduct({
      variables: {
        input: {
          categoryId,
          brand,
          itemDescription,
          locale: 'en',
          itemMeasure,
          itemSize,
          itemPackName: itemPackName === 'null' ? null : itemPackName,
          itemSellPackName,
          itemSellQuantity,
          externalId,
          inceptionType: InceptionTypeEnum.Manual,
        },
      },
      onCompleted: async (data) => {
        const createdProductId = Number(data.createInformalProduct.informalProduct?.id)
        if (code) {
          await createOrUpdatePartlyProduct({
            variables: {
              input: {
                productId: createdProductId,
                organisationId: Number(currentOrganisation?.id),
                code: code,
              },
            },
          })
        }
        createReceivingDocumentLineItem({
          variables: {
            input: {
              receivingDocumentId: Number(id),
              productId: createdProductId,
              description: itemDescription,
              quantity,
              unitValue: unitPrice,
            },
          },
          onCompleted: () => {
            navigate(
              generatePath('/purchase-orders/:purchaseOrderId/receiving-notes/:receivingNoteId/draft/summary', {
                receivingNoteId: String(id),
                purchaseOrderId: String(purchaseOrderId),
              })
            )
          },
        })
      },
      onError() {
        alertDialog({ type: 'error' })
      },
    })
  }

  return {
    onCreateAndAddProductGRNote,
    loading: createInformalProductLoading || createReceivingDocumentLineLoading,
  }
}
