import { Trans, useTranslation } from 'react-i18next'

import { Product } from '@/graphql/purchase-plus/generated/purchasePlus_graphql'
import { Product as LegacyProduct, Supplier } from '@/graphql/purchasing/generated/purchasing_graphql'
import useSessionUser from '@/modules/access/hooks/useSessionUser'
import { formatProductItem } from '@/modules/requisitions/utils/formatProductItem'
import { Input as FormatProductInput } from '@/modules/requisitions/utils/formatProductItem'
import { Accordion } from '@/modules/shared/components/accordion/Accordion'
import { ImageItem } from '@/modules/shared/components/image/ImageItem'
import { Line } from '@/modules/shared/components/product-modal/ProductModal'
import { BooleanItem } from '@/modules/shared/components/table/table-column-items/shared/BooleanItem'
import { TileDetailsTableItem } from '@/modules/shared/components/tile/tile-items/TileDetailsTableItem'
import { useMoney } from '@/modules/shared/hooks/useMoney'
import { isProductMatched } from '@/modules/shared/utils/isProductMatched'
import { titleCase } from '@/modules/shared/utils/titleCase'

interface ProductDetailsProps<T extends Line> {
  line?: T
  supplier?: Pick<Supplier, 'name'>
  product?: LegacyProduct | Product
  formatProduct?: (input: FormatProductInput) => string
}

export default function ProductDetails<T extends Line>({
  line,
  product,
  formatProduct,
  supplier,
}: ProductDetailsProps<T>) {
  const { unitPrice, image, supplierProductCode, taxPercentage, product: lineProduct, longDescription } = line || {}
  const {
    id: productId,
    itemDescription,
    brand,
    itemSize,
    itemMeasure,
    itemPackName,
    concatenatedSellUnit,
    category: productCategory,
    canonicalProductId,
    owner,
    master,
    productCode,
    itemSellQuantity,
  } = lineProduct || product || {}

  const { t } = useTranslation()
  const { formatMoney } = useMoney()
  const { currentOrganisation } = useSessionUser()

  return (
    <>
      <section data-testid="product-info" className="flex flex-col gap-4 sm:flex-row sm:items-center">
        <ImageItem
          className="size-full flex-none border sm:size-64"
          src={image || lineProduct?.image || product?.image}
          alt="product"
        />
        <div className="flex flex-col gap-4">
          <span>
            <strong className="text-base">
              {brand} {itemDescription}
            </strong>
            <p className="text-sm text-gray-500">
              {formatProduct
                ? formatProduct({
                    itemSize: itemSize || null,
                    itemMeasure: itemMeasure || null,
                    itemPackName: itemPackName || null,
                    itemSellUnit: concatenatedSellUnit || null,
                    itemSellQuantity: itemSellQuantity || null,
                  })
                : formatProductItem({
                    itemSize: itemSize || null,
                    itemMeasure: itemMeasure || null,
                    itemPackName: itemPackName || null,
                    itemSellUnit: concatenatedSellUnit || null,
                    itemSellQuantity: itemSellQuantity || null,
                  })}
            </p>
          </span>
          {line && (
            <span>
              <strong className="text-2xl">{formatMoney({ amount: unitPrice })}</strong>
              <p className="text-xs text-gray-500">
                {taxPercentage
                  ? t('general.plusTax', 'Plus {{ taxPercentage }}% Tax', {
                      taxPercentage,
                    })
                  : t('general.taxFree', 'Tax Free')}
              </p>
            </span>
          )}
          {supplier && (
            <span data-testid="product-info-supplier">
              <strong className="text-sm">{t('general.supplier', 'Supplier')}</strong>
              <p className="text-xs text-gray-800">{supplier?.name}</p>
            </span>
          )}
        </div>
      </section>
      <section className="mt-4">
        {supplier && (
          <>
            <strong className="text-sm">{t('general.supplierDescription', 'Supplier Description')}</strong>
            <div className="text-sm">
              {longDescription || (
                <p className="mt-2 border py-4 text-center text-gray-500">
                  {t(
                    'shopPage.productModal.productDetails.noLongDescription',
                    'The Supplier has not added a description.'
                  )}
                </p>
              )}
            </div>
          </>
        )}
        <Accordion
          title={t('general.productDetails', 'Product Details')}
          buttonClassName="px-3 h-11 mt-2.5"
          titleClassName="font-bold"
          data-testId="accordion-product-details-btn"
        >
          <div data-testid="product-table-info" className="mb-4 mt-2 border">
            <TileDetailsTableItem
              title={t('general.itemName', 'Item Name')}
              value={itemDescription}
              tooltip={t('shopPage.productModal.productDetails.itemNameDescription', 'The name of the product.')}
            />
            <TileDetailsTableItem
              title={t('general.brand', 'Brand')}
              value={brand}
              tooltip={t(
                'shopPage.productModal.productDetails.brandDescription',
                'The Brand of the product, if applicable. The brand will appear in front of the item name in the listing.'
              )}
            />
            <TileDetailsTableItem
              title={t('general.category', 'Category')}
              value={productCategory?.parent?.name}
              tooltip={t(
                'shopPage.productModal.productDetails.categoryDescription',
                'The Category assigned to the product.'
              )}
            />
            <TileDetailsTableItem
              title={t('general.subcategory', 'Sub-Category')}
              value={productCategory?.name}
              tooltip={t(
                'shopPage.productModal.productDetails.subCategoryDescription',
                'The Sub-Category assigned to the product.'
              )}
            />
            <TileDetailsTableItem
              title={t('general.unitSize', 'Unit Size')}
              value={itemSize}
              tooltip={t(
                'shopPage.productModal.productDetails.unitSizeDescription',
                'The size of 1 unit of this item e.g. “375” for a 375ml Can'
              )}
            />
            <TileDetailsTableItem
              title={t('general.unitOfMeasure', 'Unit of Measure')}
              value={itemMeasure}
              tooltip={t(
                'shopPage.productModal.productDetails.unitOfMeasureDescription',
                'The unit of measure used for the unit size e.g. “ml” for a 375ml Can'
              )}
            />
            <TileDetailsTableItem
              title={t('general.unitName', 'Unit Name')}
              value={itemPackName}
              tooltip={t(
                'shopPage.productModal.productDetails.unitNameDescription',
                'If applicable, the name given to the container that the item is contained in e.g “Can” for a 375ml Can'
              )}
            />
            <TileDetailsTableItem
              title={t('general.sellPack', 'Sell Pack')}
              value={titleCase(concatenatedSellUnit || '')}
              tooltip={t(
                'shopPage.productModal.productDetails.sellPackDescription',
                'The number of units contained in the Sell Pack e.g. “Carton of 24” for 24 x 375ml Can'
              )}
            />
            {currentOrganisation?.isAPurchaser && (
              <TileDetailsTableItem
                title={t('general.purchaserCode', 'Purchaser Code')}
                value={productCode || line?.purchaserProductCode}
                tooltip={
                  <Trans i18nKey="shopPage.productModal.productDetails.purchaserCodeDescription">
                    The Purchaser Code is the code that your organisation uses to search for and identify this product.
                    The Purchaser Code is optional and does not need to be unique, although we recommend that it is kept
                    unique. The Purchaser Code will only be seen by users at your organisation.
                  </Trans>
                }
              />
            )}
            <TileDetailsTableItem
              title={t('general.p+ProductCode', 'P+ Product Code')}
              value={productId}
              tooltip={t(
                'shopPage.productModal.productDetails.p+CodeDescription',
                'The unique Code that is used to identify this product in the PurchasePlus system.'
              )}
            />
            <TileDetailsTableItem
              title={t('general.p+MasterCode', 'P+ Master Code')}
              value={canonicalProductId}
              tooltip={t(
                'shopPage.productModal.productDetails.p+MasterCodeDescription',
                'If the product is a Master Product or is matched to a Master product, the unique code of the P+ Master product will be shown here.'
              )}
            />
            {supplier && (
              <TileDetailsTableItem
                title={t('general.supplierCode', 'Supplier Code')}
                value={supplierProductCode}
                tooltip={t(
                  'shopPage.productModal.productDetails.supplierCodeDescription',
                  'If applicable, the code assigned to this item by the Supplier'
                )}
              />
            )}
            <TileDetailsTableItem
              title={t('general.owner', 'Owner')}
              value={owner?.name}
              tooltip={t(
                'shopPage.productModal.productDetails.ownerDescription',
                'Indicates if the Product is owned and managed by your Organisation, owned and managed by a Supplier, or a Master product owned and managed by PurchasePlus.'
              )}
            />
            <TileDetailsTableItem
              title={t('general.matched', 'Matched')}
              value={<BooleanItem value={isProductMatched(!!master, canonicalProductId || null)} />}
              tooltip={t(
                'shopPage.productModal.productDetails.matchedDescription',
                'Indicates if the item is a P+ Master Product, or if it has been matched to a Master Product.'
              )}
            />
            <TileDetailsTableItem
              title={t('general.master', 'Master')}
              value={<BooleanItem value={!!master} />}
              tooltip={t(
                'shopPage.productModal.productDetails.masterDescription',
                'Indicates if the item is a P+ Master Product, owned and managed by PurchasePlus.'
              )}
            />
          </div>
        </Accordion>
      </section>
    </>
  )
}
