import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import * as Types from './public_graphql'

export const PublicPageInfoFragmentDoc = gql`
  fragment PublicPageInfo on PageInfo {
    startCursor
    endCursor
    hasPreviousPage
    hasNextPage
  }
` as unknown as DocumentNode<Types.PublicPageInfoFragment, unknown>
