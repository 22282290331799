import { gql } from '@apollo/client'
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'

import { PurchasePlusPageInfoFragmentDoc } from './PageInfo.generated'
import * as Types from './purchasePlus_graphql'

export const GetAllOrganisationsDocument = gql`
  query GetAllOrganisations(
    $filter: RansackFilterType
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchText: String
    $sort: [RansackSortType!]
    $isAdmin: Boolean = false
  ) {
    organisations(
      filter: $filter
      after: $after
      before: $before
      first: $first
      last: $last
      searchText: $searchText
      sort: $sort
    ) {
      edges {
        node {
          id
          name
          isASupplier
          isAPurchaser
          legalEntity
          isVerified
          businessNumber
          subscription @include(if: $isAdmin)
          billingGroup @include(if: $isAdmin) {
            id
            name
            subscription
          }
          parent {
            id
            name
          }
          createdAt
          owner {
            id
            name
          }
          children {
            totalCount
          }
          ... on Purchaser {
            accountsPayableSystem {
              id
            }
          }
        }
      }
      pageInfo {
        ...PurchasePlusPageInfo
      }
    }
  }
  ${PurchasePlusPageInfoFragmentDoc}
` as unknown as DocumentNode<Types.GetAllOrganisationsQuery, Types.GetAllOrganisationsQueryVariables>
