import { useTranslation } from 'react-i18next'

import { Fallback } from '@/modules/shared/components/fallback/Fallback'
import { useMoney } from '@/modules/shared/hooks/useMoney'

export interface PriceTaxProps {
  unitPrice?: number | null
  taxPercentage?: number | null
}

export default function PriceTax({ unitPrice, taxPercentage }: PriceTaxProps) {
  const { formatMoney } = useMoney()
  const { t } = useTranslation()
  return (
    <span>
      <p className="text-base font-bold leading-tight sm:text-lg" data-testid="price">
        <Fallback condition={!!unitPrice}>{formatMoney({ amount: unitPrice })}</Fallback>
      </p>
      <div className="text-xxs text-gray-500 sm:text-xs" data-testid="tax-percentage">
        <Fallback condition={!!taxPercentage}>
          {t('general.plusTax', 'Plus {{ taxPercentage }}% Tax', {
            taxPercentage: taxPercentage,
          })}
        </Fallback>
      </div>
    </span>
  )
}
